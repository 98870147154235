import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import Select from '../components/Select'
import logo from '../logo.png'
import { login } from '../actions/adminActions'
import Loading from '../components/Loading'

const Login = ({ history }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginType, setLoginType] = useState('')

  // * Initalization
  const dispatch = useDispatch()

  const handleLogin = () => {
    if (!username || !password || !loginType) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(login(username.toLowerCase(), password, loginType))
    }
  }

  // * On login result
  const adminLogin = useSelector((state) => state.adminLogin)
  const { loadingAdminInfo, errorAdminInfo, adminInfo } = adminLogin

  useEffect(() => {
    if (adminInfo) {
      history.push('/users')
    } else if (errorAdminInfo) {
      toast(errorAdminInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [adminInfo, errorAdminInfo])

  // * On loading
  if (loadingAdminInfo) {
    return <Loading />
  }

  return (
    <div className='flex justify-center h-screen items-center bg-gray-50'>
      <div className='w-full max-w-xs'>
        <ToastContainer />
        <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col items-center'>
          <img src={logo} alt='GoLog' className='w-1/2' />
          <h1 className='font-bold text-xl mb-2'>ParkOye</h1>
          <p className='text-sm mb-6'>Enter your credentials to sign in</p>
          <div className='mb-2 w-full'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='mobile'
            >
              Username
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-sm'
              id='username'
              placeholder='Username'
              onChange={(e) => setUsername(e.target.value)}
              disabled={loadingAdminInfo}
            />
            <div className='mb-2 w-full'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='password'
              >
                Password
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline text-sm'
                id='password'
                type='password'
                placeholder='******************'
                onChange={(e) => setPassword(e.target.value)}
                disabled={loadingAdminInfo}
              />
            </div>
            <div className='mb-6 w-full'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='loginType'
              >
                Login Type
              </label>
              <Select
                width='w-full'
                name='Login Type *'
                value={loginType}
                onChange={(e) => setLoginType(e.target.value)}
                options={[
                  { id: 'Unorganised', title: 'Unorganised' },
                  { id: 'Valet', title: 'Valet' },
                ]}
              />
            </div>
          </div>
          <div className='flex items-center justify-between mb-4'>
            <button
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
              onClick={handleLogin}
              disabled={loadingAdminInfo}
            >
              Sign In
            </button>
          </div>
        </form>
        <p className='text-center text-gray-500 text-xs'>
          &copy;2022 Linqalot (India) Pvt Ltd. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Login
