import axios from 'axios'
import {
  ADD_RATE_FAIL,
  ADD_RATE_REQUEST,
  ADD_RATE_SUCCESS,
  GET_RATES_FAIL,
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  GET_RATE_BY_ID_FAIL,
  GET_RATE_BY_ID_REQUEST,
  GET_RATE_BY_ID_SUCCESS,
  UPDATE_RATE_FAIL,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
} from '../constants/rateConstants'
import { UNORGANISED_URL } from '../constants/URL.js'

export const getRates = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_RATES_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(`${UNORGANISED_URL}/rates`, config)

    dispatch({
      type: GET_RATES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getRateById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_RATE_BY_ID_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${UNORGANISED_URL}/rates?id=${id}`,
      config
    )

    dispatch({
      type: GET_RATE_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RATE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addRate = (name, rateList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_RATE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${UNORGANISED_URL}/rates`,
      {
        name,
        rateList,
      },
      config
    )

    dispatch({
      type: ADD_RATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADD_RATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateRate = (id, rateList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_RATE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${UNORGANISED_URL}/rates/${id}`,
      {
        rateList,
      },
      config
    )

    dispatch({
      type: UPDATE_RATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_RATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
