import axios from 'axios'
import {
  DELETE_CHECKINS_FAIL,
  DELETE_CHECKINS_REQUEST,
  DELETE_CHECKINS_SUCCESS,
  DOWNLOAD_FAIL,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  FOC_FAIL,
  FOC_REQUEST,
  FOC_SUCCESS,
  GET_CHECKINS_FAIL,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  MANUAL_CHECKOUT_FAIL,
  MANUAL_CHECKOUT_REQUEST,
  MANUAL_CHECKOUT_SUCCESS,
} from '../constants/checkinConstants'
import { VALET_URL, UNORGANISED_URL } from '../constants/URL'

export const getCheckinsValet =
  (page, status, from, to, parking, vehicleNumber, mobile) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CHECKINS_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `${VALET_URL}/checkins?page=${page}&status=${status}&from=${from}&to=${to}&parking=${parking}&vehicleNumber=${vehicleNumber}&mobile=${mobile}`,
        config
      )

      dispatch({
        type: GET_CHECKINS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_CHECKINS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const manualCheckoutsValet =
  (checkins) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MANUAL_CHECKOUT_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        adminInfo.loginType === 'Valet'
          ? `${VALET_URL}/checkins/checkouts/manual`
          : `${UNORGANISED_URL}/checkins/checkouts/manual`,
        { checkins },
        config
      )

      dispatch({
        type: MANUAL_CHECKOUT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MANUAL_CHECKOUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const focValet = (checkins) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOC_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${VALET_URL}/checkins/foc`,
      { checkins },
      config
    )

    dispatch({
      type: FOC_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FOC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCheckinsUnorganised =
  (page, from, to, parking, vehicleNumber, vehicleType, isPaid) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CHECKINS_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `${UNORGANISED_URL}/checkins/report?page=${page}&from=${from}&to=${to}&parking=${parking}&vehicleNumber=${vehicleNumber}&vehicleType=${vehicleType}&isPaid=${isPaid}`,
        config
      )

      dispatch({
        type: GET_CHECKINS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_CHECKINS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const downloadValet =
  (status, from, to, parking, vehicleNumber, mobile) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${VALET_URL}/checkins/download`,
        { status, from, to, parking, vehicleNumber, mobile },
        config
      )

      dispatch({
        type: DOWNLOAD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DOWNLOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const downloadUnorganised =
  (from, to, parking, vehicleNumber, vehicleType, isPaid) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${UNORGANISED_URL}/checkins/download`,
        { from, to, parking, vehicleNumber, vehicleType, isPaid },
        config
      )

      dispatch({
        type: DOWNLOAD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DOWNLOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteCheckins = (checkins) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CHECKINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${UNORGANISED_URL}/checkins/delete`,
      { checkins },
      config
    )

    dispatch({
      type: DELETE_CHECKINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_CHECKINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
