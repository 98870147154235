export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'

export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST'
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL'
export const GET_ADMINS_RESET = 'GET_ADMINS_RESET'

export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST'
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL'
export const ADD_ADMIN_RESET = 'ADD_ADMIN_RESET'

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST'
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL'
export const UPDATE_ADMIN_RESET = 'UPDATE_ADMIN_RESET'

export const NEW_PASSWORD_ADMIN_REQUEST = 'NEW_PASSWORD_ADMIN_REQUEST'
export const NEW_PASSWORD_ADMIN_SUCCESS = 'NEW_PASSWORD_ADMIN_SUCCESS'
export const NEW_PASSWORD_ADMIN_FAIL = 'NEW_PASSWORD_ADMIN_FAIL'
export const NEW_PASSWORD_ADMIN_RESET = 'NEW_PASSWORD_ADMIN_RESET'
