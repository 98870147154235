import {
  ADD_LOCATION_FAIL,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_RESET,
  ADD_LOCATION_SUCCESS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_RESET,
  GET_LOCATIONS_SUCCESS,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_RESET,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/locationConstants'

export const getLocationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LOCATIONS_REQUEST:
      return { loadingGetLocations: true }
    case GET_LOCATIONS_SUCCESS:
      return { loadingGetLocations: false, getLocationsData: action.payload }
    case GET_LOCATIONS_FAIL:
      return { loadingGetLocations: false, errorGetLocations: action.payload }
    case GET_LOCATIONS_RESET:
      return {}
    default:
      return state
  }
}

export const addLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_LOCATION_REQUEST:
      return { loadingAddLocation: true }
    case ADD_LOCATION_SUCCESS:
      return { loadingAddLocation: false, addLocationData: action.payload }
    case ADD_LOCATION_FAIL:
      return { loadingAddLocation: false, errorAddLocation: action.payload }
    case ADD_LOCATION_RESET:
      return {}
    default:
      return state
  }
}

export const updateLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOCATION_REQUEST:
      return { loadingUpdateLocation: true }
    case UPDATE_LOCATION_SUCCESS:
      return {
        loadingUpdateLocation: false,
        updateLocationData: action.payload,
      }
    case UPDATE_LOCATION_FAIL:
      return {
        loadingUpdateLocation: false,
        errorUpdateLocation: action.payload,
      }
    case UPDATE_LOCATION_RESET:
      return {}
    default:
      return state
  }
}
