import axios from 'axios'
import {
  ADD_PARKING_FAIL,
  ADD_PARKING_REQUEST,
  ADD_PARKING_SUCCESS,
  GET_PARKINGS_FAIL,
  GET_PARKINGS_REQUEST,
  GET_PARKINGS_SUCCESS,
  GET_PARKING_BY_ID_FAIL,
  GET_PARKING_BY_ID_REQUEST,
  GET_PARKING_BY_ID_SUCCESS,
  UPDATE_PARKING_FAIL,
  UPDATE_PARKING_REQUEST,
  UPDATE_PARKING_SUCCESS,
} from '../constants/parkingConstants'
import { VALET_URL, UNORGANISED_URL } from '../constants/URL.js'

export const getParkings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PARKINGS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/parkings`
        : `${UNORGANISED_URL}/parkings`,
      config
    )

    dispatch({
      type: GET_PARKINGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PARKINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addParkingValet =
  (
    name,
    address,
    contractStartedOn,
    contractFinishedOn,
    base64code,
    rate,
    basements,
    foc,
    lpr,
    showMobile,
    onlinePayment,
    gst,
    driverLimit
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${VALET_URL}/parkings`,
        {
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          image: base64code,
          rate,
          basements,
          foc,
          lpr,
          showMobile,
          onlinePayment,
          gst,
          driverLimit,
        },
        config
      )

      dispatch({
        type: ADD_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_PARKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addParkingUnorganised =
  (
    name,
    address,
    managerAppName,
    bannerText,
    footerText,
    gst,
    location,
    rate,
    reportAdjustment,
    reportAdjustmentValue,
    barcode,
    qrcode,
    multiDevice,
    foc,
    reporting,
    displayMobile,
    statesOption,
    checkinStaticPrice,
    pass,
    customPass,
    dayDailyPass,
    dayNightDailyPass,
    weeklyDayPass,
    weeklyDayNightPass,
    monthlyDayPass,
    monthlyDayNightPass,
    vehicleTypes,
    nightCharge,
    nightChargeInPass,
    nightChargeStartTime,
    nightChargeEndTime,
    fontSize,
    resetCheckins,
    onlinePayment
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${UNORGANISED_URL}/parkings`,
        {
          name,
          address,
          managerAppName,
          bannerText,
          footerText,
          gst,
          location,
          rates: rate,
          reportAdjustment,
          reportAdjustmentValue,
          barcode,
          qrcode,
          multiDevice,
          foc,
          reporting,
          displayMobile,
          statesOption,
          checkinStaticPrice,
          pass,
          customPass,
          dayDailyPass,
          dayNightDailyPass,
          weeklyDayPass,
          weeklyDayNightPass,
          monthlyDayPass,
          monthlyDayNightPass,
          vehicleTypes,
          nightCharge,
          nightChargeInPass,
          nightChargeStartTime,
          nightChargeEndTime,
          fontSize,
          resetCheckins,
          onlinePayment,
        },
        config
      )

      dispatch({
        type: ADD_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_PARKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateParkingValet =
  (
    id,
    name,
    address,
    contractStartedOn,
    contractFinishedOn,
    base64code,
    rate,
    basements,
    foc,
    lpr,
    showMobile,
    onlinePayment,
    gst,
    driverLimit
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${VALET_URL}/parkings/${id}`,
        {
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          image: base64code,
          rate,
          basements,
          foc,
          lpr,
          showMobile,
          onlinePayment,
          gst,
          driverLimit,
        },
        config
      )

      dispatch({
        type: UPDATE_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PARKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getParkingById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PARKING_BY_ID_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${UNORGANISED_URL}/parkings/${id}/backend`,
      config
    )

    dispatch({
      type: GET_PARKING_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PARKING_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateParkingUnorganised =
  (
    id,
    name,
    address,
    managerAppName,
    bannerText,
    footerText,
    gst,
    location,
    rate,
    reportAdjustment,
    reportAdjustmentValue,
    barcode,
    qrcode,
    multiDevice,
    foc,
    reporting,
    displayMobile,
    statesOption,
    checkinStaticPrice,
    pass,
    customPass,
    dayDailyPass,
    dayNightDailyPass,
    weeklyDayPass,
    weeklyDayNightPass,
    monthlyDayPass,
    monthlyDayNightPass,
    vehicleTypes,
    nightCharge,
    nightChargeInPass,
    nightChargeStartTime,
    nightChargeEndTime,
    fontSize,
    resetCheckins,
    onlinePayment
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${UNORGANISED_URL}/parkings/${id}`,
        {
          name,
          address,
          managerAppName,
          bannerText,
          footerText,
          gst,
          location,
          rates: rate,
          reportAdjustment,
          reportAdjustmentValue,
          barcode,
          qrcode,
          multiDevice,
          foc,
          reporting,
          displayMobile,
          statesOption,
          checkinStaticPrice,
          pass,
          customPass,
          dayDailyPass,
          dayNightDailyPass,
          weeklyDayPass,
          weeklyDayNightPass,
          monthlyDayPass,
          monthlyDayNightPass,
          vehicleTypes,
          nightCharge,
          nightChargeInPass,
          nightChargeStartTime,
          nightChargeEndTime,
          fontSize,
          resetCheckins,
          onlinePayment,
        },
        config
      )

      dispatch({
        type: UPDATE_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PARKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
