import {
  ADD_VEHICLE_FAIL,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_RESET,
  ADD_VEHICLE_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLES_REQUEST,
  GET_VEHICLES_RESET,
  GET_VEHICLES_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_RESET,
  UPDATE_VEHICLE_SUCCESS,
} from '../constants/vehicleConstants'

export const getVehiclesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VEHICLES_REQUEST:
      return { loadingGetVehicles: true }
    case GET_VEHICLES_SUCCESS:
      return { loadingGetVehicles: false, getVehiclesData: action.payload }
    case GET_VEHICLES_FAIL:
      return { loadingGetVehicles: false, errorGetVehicles: action.payload }
    case GET_VEHICLES_RESET:
      return {}
    default:
      return state
  }
}

export const addVehicleReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_VEHICLE_REQUEST:
      return { loadingAddVehicle: true }
    case ADD_VEHICLE_SUCCESS:
      return { loadingAddVehicle: false, addVehicleData: action.payload }
    case ADD_VEHICLE_FAIL:
      return { loadingAddVehicle: false, errorAddVehicle: action.payload }
    case ADD_VEHICLE_RESET:
      return {}
    default:
      return state
  }
}

export const updateVehicleReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VEHICLE_REQUEST:
      return { loadingUpdateVehicle: true }
    case UPDATE_VEHICLE_SUCCESS:
      return {
        loadingUpdateVehicle: false,
        updateVehicleData: action.payload,
      }
    case UPDATE_VEHICLE_FAIL:
      return {
        loadingUpdateVehicle: false,
        errorUpdateVehicle: action.payload,
      }
    case UPDATE_VEHICLE_RESET:
      return {}
    default:
      return state
  }
}
