import axios from 'axios'
import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  NEW_PASSWORD_USER_FAIL,
  NEW_PASSWORD_USER_REQUEST,
  NEW_PASSWORD_USER_SUCCESS,
  RESET_DEVICE_ID_USER_FAIL,
  RESET_DEVICE_ID_USER_REQUEST,
  RESET_DEVICE_ID_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../constants/userConstants'
import { UNORGANISED_URL, VALET_URL } from '../constants/URL.js'

export const getUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USERS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/users`
        : `${UNORGANISED_URL}/users`,
      config
    )

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addUserValet =
  (username, name, role, parking) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${VALET_URL}/users`,
        { username, name, role, parking },
        config
      )

      dispatch({
        type: ADD_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateUserValet =
  (id, role, isActive) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_USER_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${VALET_URL}/users/${id}/update`,
        { role, isActive },
        config
      )

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const resetDeviceIdUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_DEVICE_ID_USER_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/users/${id}/reset-device-id`
        : `${UNORGANISED_URL}/users/${id}/reset-device-id`,
      {},
      config
    )

    dispatch({
      type: RESET_DEVICE_ID_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: RESET_DEVICE_ID_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const newPasswordUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_PASSWORD_USER_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/users/${id}/new-password`
        : `${UNORGANISED_URL}/users/${id}/new-password`,
      {},
      config
    )

    dispatch({
      type: NEW_PASSWORD_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NEW_PASSWORD_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addUserUnorganised =
  (username, name, role, parkings) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${UNORGANISED_URL}/users`,
        { username, name, role, parkings },
        config
      )

      dispatch({
        type: ADD_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateUserUnorganised =
  (id, isActive, parkings) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_USER_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${UNORGANISED_URL}/users/${id}/update`,
        { isActive, parkings },
        config
      )

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
