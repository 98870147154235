import axios from 'axios'
import {
  GET_PASSES_FAIL,
  GET_PASSES_REQUEST,
  GET_PASSES_SUCCESS,
} from '../constants/passConstants'
import { UNORGANISED_URL } from '../constants/URL'

export const getPasses =
  (page, from, to, parking, vehicleNumber, vehicleType) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PASSES_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `${UNORGANISED_URL}/passes/report?page=${page}&from=${from}&to=${to}&parking=${parking}&vehicleNumber=${vehicleNumber}&vehicleType=${vehicleType}`,
        config
      )

      dispatch({
        type: GET_PASSES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_PASSES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
