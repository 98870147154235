export const GET_CHECKINS_REQUEST = 'GET_CHECKINS_REQUEST'
export const GET_CHECKINS_SUCCESS = 'GET_CHECKINS_SUCCESS'
export const GET_CHECKINS_FAIL = 'GET_CHECKINS_FAIL'
export const GET_CHECKINS_RESET = 'GET_CHECKINS_RESET'

export const MANUAL_CHECKOUT_REQUEST = 'MANUAL_CHECKOUT_REQUEST'
export const MANUAL_CHECKOUT_SUCCESS = 'MANUAL_CHECKOUT_SUCCESS'
export const MANUAL_CHECKOUT_FAIL = 'MANUAL_CHECKOUT_FAIL'
export const MANUAL_CHECKOUT_RESET = 'MANUAL_CHECKOUT_RESET'

export const FOC_REQUEST = 'FOC_REQUEST'
export const FOC_SUCCESS = 'FOC_SUCCESS'
export const FOC_FAIL = 'FOC_FAIL'
export const FOC_RESET = 'FOC_RESET'

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL'
export const DOWNLOAD_RESET = 'DOWNLOAD_RESET'

export const DELETE_CHECKINS_REQUEST = 'DELETE_CHECKINS_REQUEST'
export const DELETE_CHECKINS_SUCCESS = 'DELETE_CHECKINS_SUCCESS'
export const DELETE_CHECKINS_FAIL = 'DELETE_CHECKINS_FAIL'
export const DELETE_CHECKINS_RESET = 'DELETE_CHECKINS_RESET'
