import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import moment from 'moment'
import writeXlsxFile from 'write-excel-file'

import Input from '../components/Input'
import Button from '../components/Button'
import Select from '../components/Select'
import Loading from '../components/Loading'
import { getParkings } from '../actions/parkingActions'
import {
  deleteCheckins,
  downloadUnorganised,
  getCheckinsUnorganised,
  manualCheckoutsValet,
} from '../actions/checkinActions'
import { getVehicles } from '../actions/vehicleActions'
import { GET_PARKINGS_RESET } from '../constants/parkingConstants'
import { GET_VEHICLES_RESET } from '../constants/vehicleConstants'
import {
  DELETE_CHECKINS_RESET,
  DOWNLOAD_RESET,
  GET_CHECKINS_RESET,
  MANUAL_CHECKOUT_RESET,
} from '../constants/checkinConstants'

const CheckinUnorganised = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalAmount, setTotalAmount] = useState(0)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [selected, setSelected] = useState([])

  // * Vehicle Types
  const [vehicleType, setVehicleType] = useState('')

  // * Parkings
  const [parkingsFormatted, setParkingsFormatted] = useState([])
  const [parking, setParking] = useState('')

  // * Dates
  const [dateModal, setDateModal] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ])
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [dateRange, setDateRange] = useState(false)

  useEffect(() => {
    let dateObject = state[0]
    let { startDate, endDate } = dateObject

    startDate = moment(startDate).format('YYYY-MM-DD')
    endDate = moment(endDate).format('YYYY-MM-DD')

    setFrom(startDate)
    setTo(endDate)
  }, [state])

  const submitDateRange = () => {
    setDateRange(true)
    setDateModal(false)
  }

  // * Vehicle Number
  const [vehicleNumber, setVehicleNumber] = useState('')

  // * Table
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)

  const [filteredData, setFilteredData] = useState([])
  const [order, setOrder] = useState('ASC')

  // * Table sorting
  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('ASC')
    }
  }

  const [isPaid, setIsPaid] = useState(null)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    } else {
      if (adminInfo.loginType !== 'Unorganised') {
        history.push('/')
      }
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getParkings())
    dispatch(getVehicles())
    dispatch(
      getCheckinsUnorganised(
        page,
        from,
        to,
        parking,
        vehicleNumber,
        vehicleType,
        isPaid
      )
    )
  }, [page])

  // * Get Parkings
  const getParkingsInfo = useSelector((state) => state.getParkingsInfo)
  const { errorGetParkings, getParkingsData } = getParkingsInfo

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET })
    if (getParkingsData) {
      let data = []

      getParkingsData.forEach((item) => {
        data.push({
          id: item._id,
          title:
            adminInfo.loginType === 'Valet' ? item.name : item.managerAppName,
        })
      })

      setParkingsFormatted(data)
    } else if (errorGetParkings) {
      toast(errorGetParkings, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getParkingsData, errorGetParkings])

  // * Get Vehicles
  const getVehiclesInfo = useSelector((state) => state.getVehiclesInfo)
  const { errorGetVehicles, getVehiclesData } = getVehiclesInfo

  useEffect(() => {
    dispatch({ type: GET_VEHICLES_RESET })
    if (getVehiclesData) {
      let data = []

      getVehiclesData.forEach((item) => {
        data.push({
          id: item._id,
          title: item.name,
        })
      })

      setVehicleTypes(data)
    } else if (errorGetVehicles) {
      toast(errorGetVehicles, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getVehiclesData, errorGetVehicles])

  // * Checkins
  const getCheckinsInfo = useSelector((state) => state.getCheckinsInfo)
  const { errorGetCheckins, getCheckinsData } = getCheckinsInfo

  useEffect(() => {
    dispatch({ type: GET_CHECKINS_RESET })
    if (getCheckinsData) {
      setLoading(false)
      setData(getCheckinsData.checkins)
      setPage(getCheckinsData.page)
      setPages(getCheckinsData.pages)
      setTotal(getCheckinsData.count)
      setTotalAmount(getCheckinsData.totalSum)
    } else if (errorGetCheckins) {
      setLoading(false)
      toast(errorGetCheckins, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getCheckinsData, errorGetCheckins])

  useEffect(() => {
    if (data) {
      setFilteredData(data)
    }
  }, [data])

  const headCells = [
    {
      field: 'vehicleNumber',
      title: 'Vehicle Number',
    },
    {
      field: 'mobile',
      title: 'Mobile',
    },
    {
      field: 'user',
      title: 'User',
    },
    {
      field: 'parking',
      title: 'Parking',
    },
    {
      field: 'vehicleType',
      title: 'Vehicle Type',
    },
    {
      field: 'inTime',
      title: 'In Time',
    },
    {
      field: 'outTime',
      title: 'Out Time',
    },
    {
      field: 'isCheckedOut',
      title: 'Is Checked Out?',
    },
    {
      field: 'isHelmet',
      title: 'Is Helmet?',
    },
    {
      field: 'helemtQuantity',
      title: 'Helmet Quantity',
    },
    {
      field: 'isPass',
      title: 'Is Pass?',
    },
    {
      field: 'isFOC',
      title: 'Is FOC?',
    },
    {
      field: 'amount',
      title: 'Amount',
    },
    {
      field: 'type',
      title: 'Checkout Type',
    },
    {
      field: 'manualCheckOutBy',
      title: 'Manual Check Out By',
    },
  ]

  const searchCheckins = () => {
    dispatch(
      getCheckinsUnorganised(
        page,
        from,
        to,
        parking,
        vehicleNumber,
        vehicleType,
        isPaid
      )
    )
    // setParking('')
    // setVehicleType('')
    // setVehicleNumber('')
    // setFrom('')
    // setTo('')
  }

  const allSelection = (e) => {
    if (e.target.checked) {
      const ids = []
      filteredData.forEach((item) => {
        ids.push(item._id)
      })
      setSelected(ids)
    } else {
      setSelected([])
    }
  }

  const selectSingle = (e, id) => {
    if (e.target.checked) {
      setSelected([...selected, id])
      // * Check if total rows selected is equal to total data
      if (selected.length === filteredData.length) {
        setSelected(filteredData)
      }
    } else {
      setSelected(selected.filter((item) => item !== id))
    }
  }

  // * Manual Checkouts
  const manualCheckOut = () => {
    dispatch(manualCheckoutsValet(selected))
  }

  const manualCheckoutInfo = useSelector((state) => state.manualCheckoutInfo)
  const { loadingManualCheckout, errorManualCheckout, manualCheckoutData } =
    manualCheckoutInfo

  useEffect(() => {
    dispatch({ type: MANUAL_CHECKOUT_RESET })
    if (manualCheckoutData) {
      toast(manualCheckoutData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setSelected([])
      setTimeout(() => {
        dispatch(getCheckinsUnorganised(page))
      }, 1000)
    } else if (errorManualCheckout) {
      toast(errorManualCheckout, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [manualCheckoutData, errorManualCheckout])

  // * Delete Checkins
  const deleteCheckinsHandler = () => {
    dispatch(deleteCheckins(selected))
  }

  const deleteCheckinsInfo = useSelector((state) => state.deleteCheckinsInfo)
  const { loadingDeleteCheckins, errorDeleteCheckins, deleteCheckinsData } =
    deleteCheckinsInfo

  useEffect(() => {
    dispatch({ type: DELETE_CHECKINS_RESET })
    if (deleteCheckinsData) {
      toast(deleteCheckinsData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setSelected([])
      setTimeout(() => {
        dispatch(getCheckinsUnorganised(page))
      }, 1000)
    } else if (errorDeleteCheckins) {
      toast(errorDeleteCheckins, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [deleteCheckinsData, errorDeleteCheckins])

  const download = async () => {
    dispatch(
      downloadUnorganised(from, to, parking, vehicleNumber, vehicleType, isPaid)
    )
  }

  const downloadInfo = useSelector((state) => state.downloadInfo)
  const { loadingDownload, downloadData, errorDownload } = downloadInfo

  useEffect(() => {
    dispatch({ type: DOWNLOAD_RESET })
    if (downloadData) {
      downloadExcel(downloadData)
    } else if (errorDownload) {
      toast(errorDownload, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [downloadData, errorDownload])

  const downloadExcel = async (downloadData) => {
    await writeXlsxFile(downloadData, {
      fileName: 'Checkins.xlsx',
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row gap-4'>
            <h1 className='text-2xl font-semibold'>Checkins</h1>
            {dateRange && (
              <p
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  fontSize: 16,
                }}
              >
                ({from} - {to})
              </p>
            )}
          </div>
          <div className='flex flex-row gap-4'>
            <Button
              custom='py-2 mt-4 md:mt-0 bg-green-500 hover:bg-green-700'
              type='button'
              onClick={download}
              text='Download'
              disabled={loadingDownload}
            />
            {selected.length > 0 && (
              <div className='flex flex-row gap-4'>
                {(adminInfo.role === 'Super Admin' ||
                  adminInfo.role === 'Admin') && (
                  <Button
                    custom='py-2 mt-4 md:mt-0 bg-red-500 hover:bg-red-700'
                    type='button'
                    onClick={manualCheckOut}
                    text='Check Out'
                    disabled={loadingManualCheckout}
                  />
                )}
                <Button
                  custom='py-2 mt-4 md:mt-0 bg-red-500 hover:bg-red-700'
                  type='button'
                  onClick={deleteCheckinsHandler}
                  text='Delete'
                  disabled={loadingDeleteCheckins}
                />
              </div>
            )}
          </div>
        </div>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex flex-col md:flex-row justify-between'>
            <div className='flex flex-wrap md:flex-row gap-4 items-center'>
              <Select
                name='Parking'
                value={parking}
                onChange={(e) => setParking(e.target.value)}
                options={parkingsFormatted}
              />
              <Select
                name='Vehicle Type'
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                options={vehicleTypes}
              />
              <button
                className='py-1 px-10 border-2 border-gray-400 rounded text-md'
                type='button'
                onClick={() => setDateModal(true)}
              >
                Date Filter
              </button>
              <Input
                name='Vehicle Number'
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
              />
              <Select
                name='Is Paid'
                value={isPaid}
                onChange={(e) => setIsPaid(e.target.value)}
                options={[
                  { id: '', title: 'Both' },
                  { id: true, title: 'Yes' },
                  { id: false, title: 'No' },
                ]}
              />
            </div>
            <Button
              custom='py-2 mt-4 md:mt-0 h-10'
              type='button'
              onClick={searchCheckins}
              text='Search'
            />
          </div>
        </div>
        <div
          className='w-full bg-white mb-20'
          style={{ overflowX: 'auto', height: '85%' }}
        >
          <table
            className='w-full'
            style={{ height: '100%', position: 'relative' }}
          >
            <thead
              className='w-full bg-white'
              style={{ position: 'sticky', insetBlockStart: 0 }}
            >
              <tr className='w-full flex flex-row p-4 gap-2 items-center border border-b-1'>
                <th className='mr-5'>
                  <input
                    type='checkbox'
                    onChange={(e) => allSelection(e)}
                    checked={selected.length === filteredData.length}
                  />
                </th>
                {headCells.map((item) => (
                  <th
                    className='w-44 flex items-center'
                    key={item.field}
                    style={{ cursor: 'pointer' }}
                    onClick={() => sorting(item.field)}
                  >
                    <p className='text-sm font-semibold'>{item.title}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ overflowY: 'auto', height: '60%' }}>
              {filteredData.map((item, index) => (
                <tr
                  key={item.index}
                  className='w-full flex flex-row p-4 gap-2 items-center border border-b-1'
                >
                  <td className='mr-5'>
                    <input
                      type='checkbox'
                      onChange={(e) => selectSingle(e, item._id)}
                      checked={selected.includes(item._id)}
                    />
                  </td>
                  {headCells.map((head) => (
                    <>
                      {head.field === 'parking' ||
                      head.field === 'vehicleType' ? (
                        <td className='w-44 flex items-center'>
                          <p style={{ fontSize: 14 }}>
                            {item[head.field] ? item[head.field].name : 'N/A'}
                          </p>
                        </td>
                      ) : head.field === 'user' ? (
                        <td className='w-44 flex items-center'>
                          <p style={{ fontSize: 14 }}>
                            {item[head.field]
                              ? item[head.field].username
                              : 'N/A'}
                          </p>
                        </td>
                      ) : head.field === 'isCheckedOut' ||
                        head.field === 'isHelmet' ||
                        head.field === 'isPass' ||
                        head.field === 'isFOC' ? (
                        <td className='w-44 flex items-center'>
                          <p style={{ fontSize: 14 }}>
                            {item[head.field] && item[head.field] === true
                              ? 'Yes'
                              : 'No'}
                          </p>
                        </td>
                      ) : (
                        <td className='w-44 flex items-center'>
                          <p style={{ fontSize: 14 }}>
                            {item[head.field]?.toString() || 'N/A'}
                          </p>
                        </td>
                      )}
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className='w-full bg-white border border-t-1 flex flex-row justify-between p-4'
            style={{ position: 'sticky', insetBlockEnd: 0 }}
          >
            <p className='text-sm'>
              Showing{' '}
              {page === 1
                ? '0 - ' + filteredData.length * page
                : filteredData.length * (page - 1) +
                  1 +
                  ' - ' +
                  filteredData.length * page}
              , Out of {total}
            </p>
            <div className='flex flex-row items-center gap-2'>
              <button
                className='border border-1 px-1 border-black text-sm'
                style={{ cursor: 'pointer' }}
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
              >
                &#171;
              </button>
              <button
                className='border border-1 px-1 border-black text-sm'
                style={{ cursor: 'pointer' }}
                onClick={() => setPage(page + 1)}
                disabled={page === pages}
              >
                &#187;
              </button>
            </div>
          </div>
        </div>
        {dateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-full'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Date Range</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <DateRangePicker
                      onChange={(item) => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction='horizontal'
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setDateModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={submitDateRange}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default CheckinUnorganised
