import axios from 'axios'
import {
  ADD_LOCATION_FAIL,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/locationConstants'
import { UNORGANISED_URL } from '../constants/URL.js'

export const getLocations = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LOCATIONS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(`${UNORGANISED_URL}/locations`, config)

    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_LOCATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addLocation =
  (
    name,
    address,
    contractStartedOn,
    contractFinishedOn,
    latitude,
    longitude,
    inventory,
    rateToDisplay,
    note,
    displayName,
    showOnApp
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_LOCATION_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${UNORGANISED_URL}/locations`,
        {
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          latitude,
          longitude,
          inventory,
          rateToDisplay,
          note,
          displayName,
          showOnApp,
        },
        config
      )

      dispatch({
        type: ADD_LOCATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateLocation =
  (
    id,
    name,
    address,
    contractStartedOn,
    contractFinishedOn,
    latitude,
    longitude,
    inventory,
    rateToDisplay,
    note,
    displayName,
    showOnApp
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_LOCATION_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${UNORGANISED_URL}/locations/${id}`,
        {
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          latitude,
          longitude,
          inventory,
          rateToDisplay,
          note,
          displayName,
          showOnApp,
        },
        config
      )

      dispatch({
        type: UPDATE_LOCATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
