import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { login, logout } from '../actions/adminActions'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import Home from '@material-ui/icons/Home'
import GroupIcon from '@material-ui/icons/Group'
import ExitToApp from '@material-ui/icons/ExitToApp'
import TransformOutlined from '@material-ui/icons/TransformOutlined'
import LocationCity from '@material-ui/icons/LocationCity'
import DirectionsCar from '@material-ui/icons/DirectionsCar'
import logo from '../logo.png'
import pjson from '../../package.json'

const Sidebar = ({ route, history }) => {
  // * Initialization
  const dispatch = useDispatch()
  const location = useLocation()

  // * On logout click
  const logoutClick = () => {
    dispatch(logout())
  }

  // * States
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [loginType, setLoginType] = useState('')

  // * Get logged in user's info
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    if (adminInfo) {
      setName(adminInfo.name)
      setRole(adminInfo.role)
      setLoginType(adminInfo.loginType)
    }
  }, [adminInfo])

  return (
    <div className='w-1/5 min-h-screen absolute sm:relative bg-gray-800 shadow flex-col justify-between hidden sm:flex'>
      <div className='px-8'>
        <div className='h-16 w-full flex flex-row items-center gap-2 mt-5'>
          <img src={logo} alt='ParkOye Valet' className='w-10' />
          <h3 className='text-2xl font-semibold text-white'>
            ParkOye {loginType === 'Valet' ? 'Valet' : 'Unorganised'}
          </h3>
          {/* <p className='text-sm text-white mt-1'>(v{pjson.version})</p> */}
        </div>
        <ul className='mt-12'>
          {loginType === 'Valet' ? (
            <>
              {(role === 'Super Admin' || role === 'Admin') && (
                <Link
                  className={`flex w-full justify-between ${
                    location.pathname === '/parkings'
                      ? 'text-gray-300'
                      : 'text-gray-600'
                  } hover:text-gray-500 cursor-pointer items-center mb-6`}
                  to='/parkings'
                >
                  <div className='flex items-center'>
                    <Home />
                    <span className='text-md ml-2'>Parkings</span>
                  </div>
                </Link>
              )}
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/users'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/users'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Users</span>
                </div>
              </Link>
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/checkins'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/checkins'
              >
                <div className='flex items-center'>
                  <TransformOutlined />
                  <span className='text-md ml-2'>Checkins</span>
                </div>
              </Link>
              {(role === 'Super Admin' || role === 'Admin') && (
                <Link
                  className={`flex w-full justify-between ${
                    location.pathname === '/admins'
                      ? 'text-gray-300'
                      : 'text-gray-600'
                  } hover:text-gray-500 cursor-pointer items-center mb-6`}
                  to='/admins'
                >
                  <div className='flex items-center'>
                    <SupervisedUserCircleIcon />
                    <span className='text-md ml-2'>Admins</span>
                  </div>
                </Link>
              )}
            </>
          ) : (
            <>
              {(role === 'Super Admin' || role === 'Admin') && (
                <>
                  <Link
                    className={`flex w-full justify-between ${
                      location.pathname === '/locations'
                        ? 'text-gray-300'
                        : 'text-gray-600'
                    } hover:text-gray-500 cursor-pointer items-center mb-6`}
                    to='/locations'
                  >
                    <div className='flex items-center'>
                      <LocationCity />
                      <span className='text-md ml-2'>Locations</span>
                    </div>
                  </Link>
                  <Link
                    className={`flex w-full justify-between ${
                      location.pathname === '/rates'
                        ? 'text-gray-300'
                        : 'text-gray-600'
                    } hover:text-gray-500 cursor-pointer items-center mb-6`}
                    to='/rates'
                  >
                    <div className='flex items-center'>
                      <LocationCity />
                      <span className='text-md ml-2'>Rates</span>
                    </div>
                  </Link>
                </>
              )}
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/parkings'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/parkings'
              >
                <div className='flex items-center'>
                  <Home />
                  <span className='text-md ml-2'>Parkings</span>
                </div>
              </Link>
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/users'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/users'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Users</span>
                </div>
              </Link>
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/customers'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/customers'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Customers</span>
                </div>
              </Link>
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/passes'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/passes'
              >
                <div className='flex items-center'>
                  <TransformOutlined />
                  <span className='text-md ml-2'>Passes</span>
                </div>
              </Link>
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/checkins'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/checkins'
              >
                <div className='flex items-center'>
                  <TransformOutlined />
                  <span className='text-md ml-2'>Checkins</span>
                </div>
              </Link>
              {(role === 'Super Admin' || role === 'Admin') && (
                <>
                  <Link
                    className={`flex w-full justify-between ${
                      location.pathname === '/admins'
                        ? 'text-gray-300'
                        : 'text-gray-600'
                    } hover:text-gray-500 cursor-pointer items-center mb-6`}
                    to='/admins'
                  >
                    <div className='flex items-center'>
                      <SupervisedUserCircleIcon />
                      <span className='text-md ml-2'>Admins</span>
                    </div>
                  </Link>
                  <Link
                    className={`flex w-full justify-between ${
                      location.pathname === '/vehicles'
                        ? 'text-gray-300'
                        : 'text-gray-600'
                    } hover:text-gray-500 cursor-pointer items-center mb-6`}
                    to='/vehicles'
                  >
                    <div className='flex items-center'>
                      <DirectionsCar />
                      <span className='text-md ml-2'>Vehicles</span>
                    </div>
                  </Link>
                </>
              )}
            </>
          )}
        </ul>
      </div>
      <div className='px-8 border-t border-gray-700'>
        <ul className='w-full flex items-center justify-between bg-gray-800'>
          <li
            className='flex items-center w-full cursor-pointer text-white pt-5 pb-3'
            onClick={logoutClick}
          >
            <ExitToApp />
            <div className='flex flex-col'>
              <span className='text-sm ml-6'>{name}</span>
              <span className='text-sm ml-6'>Role: {role}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
