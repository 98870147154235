import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Input from '../components/Input'
import Select from '../components/Select'
import { getLocations } from '../actions/locationActions'
import { getVehicles } from '../actions/vehicleActions'
import { getRates } from '../actions/rateActions'
import { addParkingUnorganised } from '../actions/parkingActions'
import { GET_LOCATIONS_RESET } from '../constants/locationConstants'
import { GET_VEHICLES_RESET } from '../constants/vehicleConstants'
import { GET_RATES_RESET } from '../constants/rateConstants'
import { ADD_PARKING_RESET } from '../constants/parkingConstants'

const AddParking = ({ history }) => {
  const [name, setName] = useState('') //
  const [address, setAddress] = useState('') //
  const [rate, setRate] = useState(0) //
  const [foc, setFoc] = useState(false) //
  const [gst, setGst] = useState('') //
  const [locations, setLocations] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [rates, setRates] = useState([])
  const [bannerText, setBannerText] = useState('')
  const [footerText, setFooterText] = useState('')
  const [barcode, setBarcode] = useState(false)
  const [qrcode, setQrcode] = useState(false)
  const [pass, setPass] = useState(false)
  const [multiDevice, setMultiDevice] = useState(false)
  const [reporting, setReporting] = useState(false)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [customPass, setCustomPass] = useState(false)
  const [fontSize, setFontSize] = useState('Normal')
  const [location, setLocation] = useState(false)
  const [monthlyDayNightPass, setMonthlyDayNightPass] = useState(false)
  const [monthlyDayPass, setMonthlyDayPass] = useState(false)
  const [weeklyDayNightPass, setWeeklyDayNightPass] = useState(false)
  const [weeklyDayPass, setWeeklyDayPass] = useState(false)
  const [dayNightDailyPass, setDayNightDailyPass] = useState(false)
  const [dayDailyPass, setDayDailyPass] = useState(false)
  const [nightCharge, setNightCharge] = useState(false)
  const [nightChargeInPass, setNightChargeInPass] = useState(false)
  const [nightChargeStartTime, setNightChargeStartTime] = useState('22:00')
  const [nightChargeEndTime, setNightChargeEndTime] = useState('05:00')
  const [checkinStaticPrice, setCheckinStaticPrice] = useState(false)
  const [managerAppName, setManagerAppName] = useState('')
  const [reportAdjustment, setReportAdjustment] = useState(false)
  const [reportAdjustmentValue, setReportAdjustmentValue] = useState(0)
  const [resetCheckins, setResetCheckins] = useState(false)
  const [displayMobile, setDisplayMobile] = useState(false)
  const [statesOption, setStatesOption] = useState(false)
  const [onlinePayment, setOnlinePayment] = useState(false)

  useEffect(() => {
    if (barcode === true) {
      setQrcode(false)
    }
  }, [barcode])

  useEffect(() => {
    if (qrcode === true) {
      setBarcode(false)
    }
  }, [qrcode])

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    } else {
      if (adminInfo.loginType !== 'Unorganised') {
        history.push('/')
      }
    }
  }, [adminInfo, history])

  useEffect(() => {
    dispatch(getLocations())
    dispatch(getVehicles())
    dispatch(getRates())
  }, [])

  // * Get Locations
  const getLocationsInfo = useSelector((state) => state.getLocationsInfo)
  const { errorGetLocations, getLocationsData } = getLocationsInfo

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_RESET })
    if (getLocationsData) {
      let data = []

      getLocationsData.forEach((item) => {
        data.push({
          id: item._id,
          title: item.name,
        })
      })

      setLocations(data)
    } else if (errorGetLocations) {
      toast(errorGetLocations, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getLocationsData, errorGetLocations])

  // * Get Vehicles
  const getVehiclesInfo = useSelector((state) => state.getVehiclesInfo)
  const { errorGetVehicles, getVehiclesData } = getVehiclesInfo

  useEffect(() => {
    dispatch({ type: GET_VEHICLES_RESET })
    if (getVehiclesData) {
      let data = []

      getVehiclesData.forEach((item) => {
        data.push({
          id: item._id,
          title: item.name,
        })
      })

      setVehicles(data)
    } else if (errorGetVehicles) {
      toast(errorGetVehicles, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getVehiclesData, errorGetVehicles])

  // * Get Rates
  const getRatesInfo = useSelector((state) => state.getRatesInfo)
  const { errorGetRates, getRatesData } = getRatesInfo

  useEffect(() => {
    dispatch({ type: GET_RATES_RESET })
    if (getRatesData) {
      let data = []

      getRatesData.forEach((item) => {
        data.push({
          id: item._id,
          title: item.name,
        })
      })

      setRates(data)
    } else if (errorGetRates) {
      toast(errorGetRates, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getRatesData, errorGetRates])

  const removeItemFromArray = (id) => {
    let newArray = vehicleTypes.filter((item) => item !== id)
    setVehicleTypes(newArray)
  }

  // * Add Parking
  const addParkingHandler = () => {
    if (
      !name ||
      !address ||
      !managerAppName ||
      !bannerText ||
      !footerText ||
      !location ||
      !rate ||
      vehicleTypes.length === 0 ||
      !fontSize
    ) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(
        addParkingUnorganised(
          name,
          address,
          managerAppName,
          bannerText,
          footerText,
          gst,
          location,
          rate,
          reportAdjustment,
          reportAdjustmentValue,
          barcode,
          qrcode,
          multiDevice,
          foc,
          reporting,
          displayMobile,
          statesOption,
          checkinStaticPrice,
          pass,
          customPass,
          dayDailyPass,
          dayNightDailyPass,
          weeklyDayPass,
          weeklyDayNightPass,
          monthlyDayPass,
          monthlyDayNightPass,
          vehicleTypes,
          nightCharge,
          nightChargeInPass,
          nightChargeStartTime,
          nightChargeEndTime,
          fontSize,
          resetCheckins,
          onlinePayment
        )
      )
    }
  }

  const addParkingInfo = useSelector((state) => state.addParkingInfo)
  const { loadingAddParking, errorAddParking, addParkingData } = addParkingInfo

  useEffect(() => {
    dispatch({ type: ADD_PARKING_RESET })
    if (addParkingData) {
      toast(addParkingData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      history.push('/parkings')
    } else if (errorAddParking) {
      toast(errorAddParking, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addParkingData, errorAddParking])

  return (
    <div className='w-full h-full'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-2xl font-semibold'>Add Parking</h1>
        <Button
          custom='py-2 mt-4 md:mt-0 bg-green-500 hover:bg-green-700'
          type='button'
          onClick={addParkingHandler}
          text='Submit'
          disabled={loadingAddParking}
        />
      </div>
      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'>
        <h3>Basic Details</h3>
        <div className='flex flex-row gap-4'>
          <Input
            width='flex-1'
            name='Name *'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            width='flex-1'
            name='Manager App Name *'
            value={managerAppName}
            onChange={(e) => setManagerAppName(e.target.value)}
          />
        </div>
        <div className='flex flex-row gap-4'>
          <Input
            width='flex-1'
            name='Address *'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Input
            width='flex-1'
            name='GST'
            value={gst}
            onChange={(e) => setGst(e.target.value)}
          />
        </div>
        <div className='flex flex-row gap-4'>
          <Input
            width='flex-1'
            name='Banner Text *'
            value={bannerText}
            onChange={(e) => setBannerText(e.target.value)}
          />
          <Input
            width='flex-1'
            name='Footer Text *'
            value={footerText}
            onChange={(e) => setFooterText(e.target.value)}
          />
        </div>
        <div className='flex flex-row gap-4'>
          <Select
            width='w-full'
            name='Location *'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            options={locations}
          />
          <Select
            width='w-full'
            name='Rate *'
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            options={rates}
          />
          <Select
            width='w-full'
            name='Font Size *'
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            options={[
              { id: 'Normal', title: 'Normal' },
              { id: 'Medium', title: 'Medium' },
              { id: 'Large', title: 'Large' },
            ]}
          />
        </div>
        <div className='flex flex-row gap-4'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setReportAdjustment(true)
                  : setReportAdjustment(false)
              }
              checked={reportAdjustment === true ? true : false}
            />
            <span class='text-sm'>Report Adjustment</span>
          </label>
          {reportAdjustment === true && (
            <Input
              width='flex-1'
              name='Report Adjustment Value *'
              type='number'
              value={reportAdjustmentValue}
              onChange={(e) => setReportAdjustmentValue(e.target.value)}
            />
          )}
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setResetCheckins(true)
                  : setResetCheckins(false)
              }
              checked={resetCheckins === true ? true : false}
            />
            <span class='text-sm'>Reset Checkins</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setOnlinePayment(true)
                  : setOnlinePayment(false)
              }
              checked={onlinePayment === true ? true : false}
            />
            <span class='text-sm'>Online Payment</span>
          </label>
        </div>
        <div className='flex flex-row gap-2'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setBarcode(true) : setBarcode(false)
              }
              checked={barcode === true ? true : false}
            />
            <span class='text-sm'>Barcode</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setQrcode(true) : setQrcode(false)
              }
              checked={qrcode === true ? true : false}
            />
            <span class='text-sm'>QR Code</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setMultiDevice(true)
                  : setMultiDevice(false)
              }
              checked={multiDevice === true ? true : false}
            />
            <span class='text-sm'>Sync</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setFoc(true) : setFoc(false)
              }
              checked={foc === true ? true : false}
            />
            <span class='text-sm'>FOC</span>
          </label>
        </div>
        <div className='flex flex-row gap-2'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setReporting(true)
                  : setReporting(false)
              }
              checked={reporting === true ? true : false}
            />
            <span class='text-sm'>Reporting</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setDisplayMobile(true)
                  : setDisplayMobile(false)
              }
              checked={displayMobile === true ? true : false}
            />
            <span class='text-sm'>Display Mobile</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setStatesOption(true)
                  : setStatesOption(false)
              }
              checked={statesOption === true ? true : false}
            />
            <span class='text-sm'>States Option</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCheckinStaticPrice(true)
                  : setCheckinStaticPrice(false)
              }
              checked={checkinStaticPrice === true ? true : false}
            />
            <span class='text-sm'>Static Price</span>
          </label>
        </div>
      </div>

      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'>
        <h3>Pass Details</h3>
        <div className='flex flex-row gap-2'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setPass(true) : setPass(false)
              }
              checked={pass === true ? true : false}
            />
            <span class='text-sm'>Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCustomPass(true)
                  : setCustomPass(false)
              }
              checked={customPass === true ? true : false}
            />
            <span class='text-sm'>Custom Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setDayDailyPass(true)
                  : setDayDailyPass(false)
              }
              checked={dayDailyPass === true ? true : false}
            />
            <span class='text-sm'>Daily Day Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setDayNightDailyPass(true)
                  : setDayNightDailyPass(false)
              }
              checked={dayNightDailyPass === true ? true : false}
            />
            <span class='text-sm'>Daily Day Night Pass</span>
          </label>
        </div>
        <div className='flex flex-row gap-2'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setWeeklyDayPass(true)
                  : setWeeklyDayPass(false)
              }
              checked={weeklyDayPass === true ? true : false}
            />
            <span class='text-sm'>Weekly Day Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setWeeklyDayNightPass(true)
                  : setWeeklyDayNightPass(false)
              }
              checked={weeklyDayNightPass === true ? true : false}
            />
            <span class='text-sm'>Weekly Day Night Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setMonthlyDayPass(true)
                  : setMonthlyDayPass(false)
              }
              checked={monthlyDayPass === true ? true : false}
            />
            <span class='text-sm'>Monthly Day Pass</span>
          </label>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setMonthlyDayNightPass(true)
                  : setMonthlyDayNightPass(false)
              }
              checked={monthlyDayNightPass === true ? true : false}
            />
            <span class='text-sm'>Monthly Day Night Pass</span>
          </label>
        </div>
      </div>
      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'>
        <h3>Vehicle Types *</h3>
        <div className='flex flex-wrap gap-x-60 gap-y-4'>
          {vehicles.map((item) => (
            <label class='w-48 text-gray-500 font-bold' key={item.id}>
              <input
                class='mr-2 leading-tight'
                type='checkbox'
                onChange={(e) =>
                  e.target.checked === true
                    ? setVehicleTypes([...vehicleTypes, item.id])
                    : removeItemFromArray(item.id)
                }
              />
              <span class='text-sm'>{item.title}</span>
            </label>
          ))}
        </div>
      </div>
      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'>
        <h3>Night Charge</h3>
        <div className='flex flex-row gap-2'>
          <label class='flex-1 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setNightCharge(true)
                  : setNightCharge(false)
              }
              checked={nightCharge === true ? true : false}
            />
            <span class='text-sm'>Night Charge</span>
          </label>
          {nightCharge === true && (
            <label class='flex-1 text-gray-500 font-bold'>
              <input
                class='mr-2 leading-tight'
                type='checkbox'
                onChange={(e) =>
                  e.target.checked === true
                    ? setNightChargeInPass(true)
                    : setNightChargeInPass(false)
                }
                checked={nightChargeInPass === true ? true : false}
              />
              <span class='text-sm'>Night Charge in Pass</span>
            </label>
          )}
        </div>
        {nightCharge === true && (
          <div className='flex flex-row gap-4'>
            <Input
              width='flex-1'
              name='Night Charge Start Time *'
              value={nightChargeStartTime}
              onChange={(e) => setNightChargeStartTime(e.target.value)}
            />
            <Input
              width='flex-1'
              name='Night Charge End Time *'
              value={nightChargeEndTime}
              onChange={(e) => setNightChargeEndTime(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'></div>
    </div>
  )
}

export default AddParking
