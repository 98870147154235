import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

import Button from '../components/Button'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { getRates } from '../actions/rateActions'
import { GET_RATES_RESET } from '../constants/rateConstants'

const Rate = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getRates())
  }, [])

  // * Get Parkings
  const getRatesInfo = useSelector((state) => state.getRatesInfo)
  const { errorGetRates, getRatesData } = getRatesInfo

  useEffect(() => {
    dispatch({ type: GET_RATES_RESET })
    if (getRatesData) {
      setLoading(false)
      setData(getRatesData)
    } else if (errorGetRates) {
      setLoading(false)
      toast(errorGetRates, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getRatesData, errorGetRates])

  const headCells = [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => {
        return (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => history.push('/rate/' + rowData._id)}
          >
            {rowData.name}
          </div>
        )
      },
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return <p>{moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
      },
    },
  ]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Rates</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => history.push('/rates/add')}
              text='Add a Rate'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Rate' />
      </div>
    </>
  )
}

export default Rate
