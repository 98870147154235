import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Select from '../components/Select'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import {
  addUserUnorganised,
  addUserValet,
  getUsers,
  updateUserUnorganised,
  updateUserValet,
} from '../actions/userActions'
import { getParkings } from '../actions/parkingActions'
import { GET_PARKINGS_RESET } from '../constants/parkingConstants'
import {
  ADD_USER_RESET,
  GET_USERS_RESET,
  UPDATE_USER_RESET,
} from '../constants/userConstants'

const User = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addUserModal, setAddUserModal] = useState(false)
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('')
  const [isActive, setIsActive] = useState('')
  const [passwordModal, setPasswordModal] = useState(false)
  const [parking, setParking] = useState('')
  const [parkingsFormatted, setParkingsFormatted] = useState([])
  const [updateModal, setUpdateModal] = useState(false)
  const [userToUpdate, setUserToUpdate] = useState('')
  const [unorgParkings, setUnorgParkings] = useState([])

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getUsers())
    dispatch(getParkings())
  }, [])

  // * Get Users
  const getUsersInfo = useSelector((state) => state.getUsersInfo)
  const { errorGetUsers, getUsersData } = getUsersInfo

  useEffect(() => {
    dispatch({ type: GET_USERS_RESET })
    if (getUsersData) {
      setLoading(false)
      setData(getUsersData)
    } else if (errorGetUsers) {
      setLoading(false)
      toast(errorGetUsers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getUsersData, errorGetUsers])

  // * Get Parkings
  const getParkingsInfo = useSelector((state) => state.getParkingsInfo)
  const { errorGetParkings, getParkingsData } = getParkingsInfo

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET })
    if (getParkingsData) {
      setLoading(false)
      setParkingsFormatted(getParkingsData)
    } else if (errorGetParkings) {
      setLoading(false)
      toast(errorGetParkings, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getParkingsData, errorGetParkings])

  const closeAddUserModal = () => {
    setUsername('')
    setName('')
    setRole('')
    setParking([])
    setUnorgParkings([])
    setAddUserModal(false)
  }

  const addUserHandler = () => {
    if (adminInfo && adminInfo.loginType === 'Valet') {
      if (!username || !name || !role || !parking) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        dispatch(addUserValet(username, name, role, parking))
      }
    } else {
      if (!username || !name || !role) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        if (role === 'Attendant') {
          if (unorgParkings.length === 0 || unorgParkings.length > 1) {
            toast('Must select 1 parking', {
              type: 'error',
              hideProgressBar: true,
              autoClose: 2000,
            })
            return
          }
        } else {
          if (unorgParkings.length === 0) {
            toast('Parking is mandatory', {
              type: 'error',
              hideProgressBar: true,
              autoClose: 2000,
            })
            return
          }
        }

        dispatch(
          addUserUnorganised(username.toString(), name, role, unorgParkings)
        )
      }
    }
  }

  const addUserInfo = useSelector((state) => state.addUserInfo)
  const { loadingAddUser, addUserData, errorAddUser } = addUserInfo

  useEffect(() => {
    dispatch({ type: ADD_USER_RESET })
    if (addUserData) {
      setPassword(addUserData)
      setPasswordModal(true)
      closeAddUserModal()
      toast('User added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else if (errorAddUser) {
      toast(errorAddUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addUserData, errorAddUser])

  const closePasswordModal = () => {
    setPassword('')
    setPasswordModal(false)
    setTimeout(() => {
      dispatch(getUsers())
    }, 1000)
  }

  const updatedUser = (item) => {
    setUpdateModal(true)
    setUserToUpdate(item._id)
    setRole(item.role)
    setIsActive(item.isActive)

    let data = []
    item.parkings.forEach((obj) => {
      data.push(obj._id)
    })

    setUnorgParkings(data)
  }

  const closeUpdateUserModal = () => {
    setUserToUpdate('')
    setRole('')
    setIsActive('')
    setUpdateModal(false)
  }

  const updateUserHandler = () => {
    if (adminInfo && adminInfo.loginType === 'Valet') {
      if (!userToUpdate || isActive === '' || !role) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        dispatch(updateUserValet(userToUpdate, role, isActive))
      }
    } else {
      if (isActive === '') {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        if (role === 'Attendant') {
          if (unorgParkings.length === 0 || unorgParkings.length > 1) {
            toast('Must select 1 parking', {
              type: 'error',
              hideProgressBar: true,
              autoClose: 2000,
            })
            return
          }
        } else {
          if (unorgParkings.length === 0) {
            toast('Please select valid parkings from the list', {
              type: 'error',
              hideProgressBar: true,
              autoClose: 2000,
            })
            return
          }
        }

        dispatch(updateUserUnorganised(userToUpdate, isActive, unorgParkings))
      }
    }
  }

  const updateUserInfo = useSelector((state) => state.updateUserInfo)
  const { loadingUpdateUser, updateUserData, errorUpdateUser } = updateUserInfo

  useEffect(() => {
    dispatch({ type: UPDATE_USER_RESET })
    if (updateUserData) {
      closeUpdateUserModal()
      toast(updateUserData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getUsers())
      }, 1000)
    } else if (errorUpdateUser) {
      toast(errorUpdateUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateUserData, errorUpdateUser])

  const headCells =
    adminInfo && adminInfo.loginType === 'Valet'
      ? [
          {
            field: 'username',
            title: 'Username',
            render: (rowData) => {
              return (
                <div
                  className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                  onClick={() => updatedUser(rowData)}
                >
                  {rowData.username}
                </div>
              )
            },
          },
          {
            field: 'pin',
            title: 'Pin',
          },
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'parking.name',
            title: 'Parking',
          },
          {
            field: 'role',
            title: 'Role',
          },
          {
            field: 'status',
            title: 'Status',
            render: (rowData) => {
              return rowData.status === 'Available' ? (
                <div className='font-bold text-md cursor-pointer text-green-800 bg-green-100 p-2 flex justify-center rounded'>
                  {rowData.status}
                </div>
              ) : (
                <div className='font-bold text-md cursor-pointer text-red-800 bg-red-100 p-2 flex justify-center rounded'>
                  {rowData.status}
                </div>
              )
            },
          },
          {
            field: 'isActive',
            title: 'Is Active?',
            render: (rowData) => {
              return <p>{rowData.isActive === true ? 'Yes' : 'No'}</p>
            },
          },
        ]
      : [
          {
            field: 'username',
            title: 'Username',
            render: (rowData) => {
              return (
                <div
                  className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                  onClick={() => updatedUser(rowData)}
                >
                  {rowData.username}
                </div>
              )
            },
          },
          {
            field: 'pin',
            title: 'Pin',
          },
          {
            field: 'name',
            title: 'Name',
          },
          {
            field: 'role',
            title: 'Role',
          },
          {
            field: 'isActive',
            title: 'Is Active?',
            render: (rowData) => {
              return <p>{rowData.isActive === true ? 'Yes' : 'No'}</p>
            },
          },
        ]

  const removeItemsFromArray = (id) => {
    let newArray = unorgParkings.filter((item) => item !== id)
    setUnorgParkings(newArray)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Users</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => setAddUserModal(true)}
              text='Add a User'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='User' />
        {addUserModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add User</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Username *'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Name *'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <Select
                      width='w-full mb-4'
                      name='Role *'
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={
                        adminInfo && adminInfo.loginType === 'Valet'
                          ? [
                              { id: 'Supervisor', title: 'Supervisor' },
                              { id: 'Driver', title: 'Driver' },
                            ]
                          : [
                              { id: 'Attendant', title: 'Attendant' },
                              { id: 'Manager', title: 'Manager' },
                            ]
                      }
                    />
                    {adminInfo && adminInfo.loginType === 'Valet' ? (
                      <Select
                        width='w-full mb-4'
                        name='Parking *'
                        value={parking}
                        onChange={(e) => setParking(e.target.value)}
                        options={parkingsFormatted}
                      />
                    ) : (
                      <>
                        <p className='mb-2 text-sm'>Select Parkings *</p>
                        <div class='flex flex-wrap md:items-center mb-4 h-48 overflow-y-auto'>
                          {parkingsFormatted.map((item) => (
                            <label class='w-1/2 text-gray-500 font-bold mt-2'>
                              <input
                                class='mr-2 leading-tight'
                                type='checkbox'
                                onChange={(e) =>
                                  e.target.checked === true
                                    ? setUnorgParkings([
                                        ...unorgParkings,
                                        item._id,
                                      ])
                                    : removeItemsFromArray(item._id)
                                }
                              />
                              <span class='text-sm'>
                                {adminInfo && adminInfo.loginType === 'Valet'
                                  ? item.name
                                  : item.managerAppName}
                              </span>
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddUserModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addUserHandler}
                      disabled={loadingAddUser}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {passwordModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Password</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='flex-1'
                      name='Password *'
                      value={password}
                      onChange={null}
                      disabled
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closePasswordModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {updateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Update User</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    {adminInfo && adminInfo.loginType === 'Valet' && (
                      <Select
                        width='w-full mb-4'
                        name='Role *'
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        options={[
                          { id: 'Supervisor', title: 'Supervisor' },
                          { id: 'Driver', title: 'Driver' },
                        ]}
                      />
                    )}
                    <Select
                      width='w-full mb-4'
                      name='Is Active *'
                      value={isActive}
                      onChange={(e) => setIsActive(e.target.value)}
                      options={[
                        { id: true, title: 'Yes' },
                        { id: false, title: 'No' },
                      ]}
                    />
                    {adminInfo && adminInfo.loginType === 'Unorganised' && (
                      <>
                        <p className='mb-2 text-sm'>Select Parkings *</p>
                        <div class='flex flex-wrap md:items-center mb-4 h-48 overflow-y-auto'>
                          {parkingsFormatted.map((item) => (
                            <label class='w-1/2 text-gray-500 font-bold mt-2'>
                              <input
                                class='mr-2 leading-tight'
                                type='checkbox'
                                onChange={(e) =>
                                  e.target.checked === true
                                    ? setUnorgParkings([
                                        ...unorgParkings,
                                        item._id,
                                      ])
                                    : removeItemsFromArray(item._id)
                                }
                                checked={unorgParkings.includes(item._id)}
                              />
                              <span class='text-sm'>
                                {adminInfo && adminInfo.loginType === 'Valet'
                                  ? item.name
                                  : item.managerAppName}
                              </span>
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeUpdateUserModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={updateUserHandler}
                      disabled={loadingUpdateUser}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default User
