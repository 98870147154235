export const GET_VEHICLES_REQUEST = 'GET_VEHICLES_REQUEST'
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS'
export const GET_VEHICLES_FAIL = 'GET_VEHICLES_FAIL'
export const GET_VEHICLES_RESET = 'GET_VEHICLES_RESET'

export const ADD_VEHICLE_REQUEST = 'ADD_VEHICLE_REQUEST'
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS'
export const ADD_VEHICLE_FAIL = 'ADD_VEHICLE_FAIL'
export const ADD_VEHICLE_RESET = 'ADD_VEHICLE_RESET'

export const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST'
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS'
export const UPDATE_VEHICLE_FAIL = 'UPDATE_VEHICLE_FAIL'
export const UPDATE_VEHICLE_RESET = 'UPDATE_VEHICLE_RESET'
