import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import {
  addAdminReducer,
  adminLoginReducer,
  getAdminsReducer,
  newPasswordAdminReducer,
  updateAdminReducer,
} from './reducers/adminReducers'
import {
  addParkingReducer,
  getParkingByIdReducer,
  getParkingsReducer,
  updateParkingReducer,
} from './reducers/parkingReducers'
import {
  addUserReducer,
  getUsersReducer,
  newPasswordUserReducer,
  resetDeviceIdUserReducer,
  updateUserReducer,
} from './reducers/userReducers'
import {
  deleteCheckinsReducer,
  downloadReducer,
  focReducer,
  getCheckinsReducer,
  manualCheckoutReducer,
} from './reducers/checkinReducers'
import {
  addLocationReducer,
  getLocationsReducer,
  updateLocationReducer,
} from './reducers/locationReducers'
import {
  addVehicleReducer,
  getVehiclesReducer,
  updateVehicleReducer,
} from './reducers/vehicleReducers'
import {
  addRateReducer,
  getRateByIdReducer,
  getRatesReducer,
  updateRateReducer,
} from './reducers/rateReducers'
import { getPassesReducer } from './reducers/passReducers'

const reducer = combineReducers({
  adminLogin: adminLoginReducer,
  getAdminsInfo: getAdminsReducer,
  getParkingsInfo: getParkingsReducer,
  addAdminInfo: addAdminReducer,
  updateAdminInfo: updateAdminReducer,
  newPasswordAdminInfo: newPasswordAdminReducer,
  getUsersInfo: getUsersReducer,
  addUserInfo: addUserReducer,
  updateUserInfo: updateUserReducer,
  resetDeviceIdUserInfo: resetDeviceIdUserReducer,
  newPasswordUserInfo: newPasswordUserReducer,
  getCheckinsInfo: getCheckinsReducer,
  addParkingInfo: addParkingReducer,
  updateParkingInfo: updateParkingReducer,
  manualCheckoutInfo: manualCheckoutReducer,
  focInfo: focReducer,
  getLocationsInfo: getLocationsReducer,
  addLocationInfo: addLocationReducer,
  updateLocationInfo: updateLocationReducer,
  getVehiclesInfo: getVehiclesReducer,
  addVehicleInfo: addVehicleReducer,
  updateVehicleInfo: updateVehicleReducer,
  getRatesInfo: getRatesReducer,
  addRateInfo: addRateReducer,
  updateRateInfo: updateRateReducer,
  getPassesInfo: getPassesReducer,
  getParkingByIdInfo: getParkingByIdReducer,
  downloadInfo: downloadReducer,
  deleteCheckinsInfo: deleteCheckinsReducer,
  getRateByIdInfo: getRateByIdReducer,
})

const adminInfoFromStorage = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null

const inititalState = {
  adminLogin: { adminInfo: adminInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
