export const GET_PARKINGS_REQUEST = 'GET_PARKINGS_REQUEST'
export const GET_PARKINGS_SUCCESS = 'GET_PARKINGS_SUCCESS'
export const GET_PARKINGS_FAIL = 'GET_PARKINGS_FAIL'
export const GET_PARKINGS_RESET = 'GET_PARKINGS_RESET'

export const GET_PARKING_BY_ID_REQUEST = 'GET_PARKING_BY_ID_REQUEST'
export const GET_PARKING_BY_ID_SUCCESS = 'GET_PARKING_BY_ID_SUCCESS'
export const GET_PARKING_BY_ID_FAIL = 'GET_PARKING_BY_ID_FAIL'
export const GET_PARKING_BY_ID_RESET = 'GET_PARKING_BY_ID_RESET'

export const ADD_PARKING_REQUEST = 'ADD_PARKING_REQUEST'
export const ADD_PARKING_SUCCESS = 'ADD_PARKING_SUCCESS'
export const ADD_PARKING_FAIL = 'ADD_PARKING_FAIL'
export const ADD_PARKING_RESET = 'ADD_PARKING_RESET'

export const UPDATE_PARKING_REQUEST = 'UPDATE_PARKING_REQUEST'
export const UPDATE_PARKING_SUCCESS = 'UPDATE_PARKING_SUCCESS'
export const UPDATE_PARKING_FAIL = 'UPDATE_PARKING_FAIL'
export const UPDATE_PARKING_RESET = 'UPDATE_PARKING_RESET'
