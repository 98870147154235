import axios from 'axios'
import {
  ADD_ADMIN_FAIL,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  GET_ADMINS_FAIL,
  GET_ADMINS_REQUEST,
  GET_ADMINS_SUCCESS,
  NEW_PASSWORD_ADMIN_FAIL,
  NEW_PASSWORD_ADMIN_REQUEST,
  NEW_PASSWORD_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  UPDATE_ADMIN_REQUEST,
  UPDATE_ADMIN_SUCCESS,
} from '../constants/adminConstants'
import { UNORGANISED_URL, VALET_URL } from '../constants/URL.js'

export const login = (username, password, loginType) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      loginType === 'Valet'
        ? `${VALET_URL}/admins/login`
        : `${UNORGANISED_URL}/admins/login`,
      { username, password },
      config
    )

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: { ...data, loginType },
    })

    localStorage.setItem('adminInfo', JSON.stringify({ ...data, loginType }))
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('adminInfo')
  dispatch({
    type: ADMIN_LOGOUT,
  })
}

export const getAdmins = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ADMINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/admins`
        : `${UNORGANISED_URL}/admins`,
      config
    )

    dispatch({
      type: GET_ADMINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_ADMINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addAdmin =
  (username, name, role, parking) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_ADMIN_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        adminInfo.loginType === 'Valet'
          ? `${VALET_URL}/admins`
          : `${UNORGANISED_URL}/admins`,
        { username, name, role, parkings: parking },
        config
      )

      dispatch({
        type: ADD_ADMIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAdmin =
  (id, isActive, name, role, parking) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ADMIN_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        adminInfo.loginType === 'Valet'
          ? `${VALET_URL}/admins/${id}`
          : `${UNORGANISED_URL}/admins/${id}`,
        { isActive, name, role, parkings: parking },
        config
      )

      dispatch({
        type: UPDATE_ADMIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const newPasswordAdmin = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_PASSWORD_ADMIN_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      adminInfo.loginType === 'Valet'
        ? `${VALET_URL}/admins/${id}/new-password`
        : `${UNORGANISED_URL}/admins/${id}/new-password`,
      {},
      config
    )

    dispatch({
      type: NEW_PASSWORD_ADMIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: NEW_PASSWORD_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
