export const GET_RATES_REQUEST = 'GET_RATES_REQUEST'
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS'
export const GET_RATES_FAIL = 'GET_RATES_FAIL'
export const GET_RATES_RESET = 'GET_RATES_RESET'

export const GET_RATE_BY_ID_REQUEST = 'GET_RATE_BY_ID_REQUEST'
export const GET_RATE_BY_ID_SUCCESS = 'GET_RATE_BY_ID_SUCCESS'
export const GET_RATE_BY_ID_FAIL = 'GET_RATE_BY_ID_FAIL'
export const GET_RATE_BY_ID_RESET = 'GET_RATE_BY_ID_RESET'

export const ADD_RATE_REQUEST = 'ADD_RATE_REQUEST'
export const ADD_RATE_SUCCESS = 'ADD_RATE_SUCCESS'
export const ADD_RATE_FAIL = 'ADD_RATE_FAIL'
export const ADD_RATE_RESET = 'ADD_RATE_RESET'

export const UPDATE_RATE_REQUEST = 'UPDATE_RATE_REQUEST'
export const UPDATE_RATE_SUCCESS = 'UPDATE_RATE_SUCCESS'
export const UPDATE_RATE_FAIL = 'UPDATE_RATE_FAIL'
export const UPDATE_RATE_RESET = 'UPDATE_RATE_RESET'
