import axios from 'axios'
import {
  ADD_VEHICLE_FAIL,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLES_REQUEST,
  GET_VEHICLES_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
} from '../constants/vehicleConstants'
import { UNORGANISED_URL } from '../constants/URL.js'

export const getVehicles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_VEHICLES_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(`${UNORGANISED_URL}/vehicles`, config)

    dispatch({
      type: GET_VEHICLES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_VEHICLES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addVehicle = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_VEHICLE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${UNORGANISED_URL}/vehicles`,
      {
        name,
      },
      config
    )

    dispatch({
      type: ADD_VEHICLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADD_VEHICLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateVehicle = (id, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_VEHICLE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${UNORGANISED_URL}/vehicles/${id}`,
      {
        name,
      },
      config
    )

    dispatch({
      type: UPDATE_VEHICLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_VEHICLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
