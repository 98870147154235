import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'

import Button from '../components/Button'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import {
  addParkingValet,
  getParkings,
  updateParkingValet,
} from '../actions/parkingActions'
import {
  ADD_PARKING_RESET,
  GET_PARKINGS_RESET,
  UPDATE_PARKING_RESET,
} from '../constants/parkingConstants'

const Parking = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('') //
  const [address, setAddress] = useState('') //
  const [contractStartedOn, setContractStartedOn] = useState(new Date())
  const [contractFinishedOn, setContractFinishedOn] = useState(new Date())
  const [rate, setRate] = useState(0) //
  const [foc, setFoc] = useState(false) //
  const [image, setImage] = useState(null)
  const [basements, setBasements] = useState('')
  const [showMobile, setShowMobile] = useState(false)
  const [lpr, setLpr] = useState(false)
  const [onlinePayment, setOnlinePayment] = useState(false)
  const [gst, setGst] = useState('') //
  const [driverLimit, setDriverLimit] = useState(0)
  const [addParkingModal, setAddParkingModal] = useState(false)
  const [id, setId] = useState('') //
  const [status, setStatus] = useState('Add') //

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getParkings())
  }, [])

  // * Get Parkings
  const getParkingsInfo = useSelector((state) => state.getParkingsInfo)
  const { errorGetParkings, getParkingsData } = getParkingsInfo

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET })
    if (getParkingsData) {
      setLoading(false)
      setData(getParkingsData)
    } else if (errorGetParkings) {
      setLoading(false)
      toast(errorGetParkings, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getParkingsData, errorGetParkings])

  const headCells =
    adminInfo && adminInfo.loginType === 'Valet'
      ? [
          {
            field: 'name',
            title: 'Name',
            render: (rowData) => {
              return (
                <div
                  className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                  onClick={() => updatedParkingModal(rowData)}
                >
                  {rowData.name}
                </div>
              )
            },
          },
          {
            field: 'address',
            title: 'Address',
          },
          {
            field: 'contractStartedOn',
            title: 'Contract Started On',
          },
          {
            field: 'onlinePayment',
            title: 'Online Payment',
          },
        ]
      : [
          {
            field: 'name',
            title: 'Name',
            render: (rowData) => {
              return (
                <div
                  className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                  onClick={() => history.push('/parking/' + rowData._id)}
                >
                  {rowData.name}
                </div>
              )
            },
          },
          {
            field: 'address',
            title: 'Address',
          },
          {
            field: 'location.name',
            title: 'Location',
          },
          {
            field: 'rates.name',
            title: 'Rate',
          },
          {
            field: 'qrCode',
            title: 'QR Code',
            render: (rowData) => {
              return <p>{rowData.qrCode === true ? 'Yes' : 'No'}</p>
            },
          },
          {
            field: 'multiDevice',
            title: 'Sync',
            render: (rowData) => {
              return <p>{rowData.multiDevice === true ? 'Yes' : 'No'}</p>
            },
          },
          {
            field: 'pass',
            title: 'Pass',
            render: (rowData) => {
              return <p>{rowData.pass === true ? 'Yes' : 'No'}</p>
            },
          },
          {
            field: 'foc',
            title: 'FOC',
            render: (rowData) => {
              return <p>{rowData.foc === true ? 'Yes' : 'No'}</p>
            },
          },
          {
            field: 'createdAt',
            title: 'Created At',
            render: (rowData) => {
              return (
                <p>{moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
              )
            },
          },
        ]

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo
      let baseURL = ''
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log('Called', reader)
        baseURL = reader.result
        // console.log(baseURL)
        resolve(baseURL)
      }
      // console.log(fileInfo)
    })
  }

  const closeAddParking = () => {
    setStatus('Add')
    setAddParkingModal(false)
    setName('')
    setAddress('')
    setContractStartedOn('')
    setContractFinishedOn('')
    setImage(null)
    setBasements('')
    setRate('')
    setLpr(false)
    setShowMobile(false)
    setOnlinePayment(false)
    setFoc(false)
    setId('')
    setGst('')
    setDriverLimit(0)
  }

  // * Add Parking
  const submitAddParkingHandler = async () => {
    if (
      !name ||
      !address ||
      !contractStartedOn ||
      !contractFinishedOn ||
      !image ||
      !rate ||
      rate == 0
    ) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      // * Image
      const base64code = await getBase64(image)

      // * Basements
      let items = []
      if (basements.includes(',')) {
        items = basements.split(',')
      } else {
        items.push(basements)
      }

      dispatch(
        addParkingValet(
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          base64code,
          rate,
          basements,
          foc,
          lpr,
          showMobile,
          onlinePayment,
          gst,
          driverLimit
        )
      )
    }
  }

  const addParkingInfo = useSelector((state) => state.addParkingInfo)
  const { loadingAddParking, errorAddParking, addParkingData } = addParkingInfo

  useEffect(() => {
    dispatch({ type: ADD_PARKING_RESET })
    if (addParkingData) {
      closeAddParking()
      toast(addParkingData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getParkings())
      }, 1000)
    } else if (errorAddParking) {
      toast(errorAddParking, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addParkingData, errorAddParking])

  const updatedParkingModal = (item) => {
    setStatus('Update')
    setAddParkingModal(true)
    setName(item.name)
    setAddress(item.address)
    setContractStartedOn(item.contractStartedOn)
    setContractFinishedOn(item.contractFinishedOn)
    setRate(item.rate)
    setBasements(item.basements.join(','))
    setFoc(item.foc)
    setShowMobile(item.showMobile)
    setLpr(item.lpr)
    setOnlinePayment(item.onlinePayment)
    setId(item._id)
    setGst(item.gst)
    setDriverLimit(item.driverLimit)
  }

  const submitUpdateParkingHandler = async () => {
    if (
      !name ||
      !address ||
      !contractStartedOn ||
      !contractFinishedOn ||
      !rate ||
      rate == 0
    ) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      // * Image
      let base64code = undefined
      if (image) {
        base64code = await getBase64(image)
      }

      // * Basements
      let items = []
      if (basements.includes(',')) {
        items = basements.split(',')
      } else {
        items.push(basements)
      }

      dispatch(
        updateParkingValet(
          id,
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          base64code,
          rate,
          basements,
          foc,
          lpr,
          showMobile,
          onlinePayment,
          gst,
          driverLimit
        )
      )
    }
  }

  const updateParkingInfo = useSelector((state) => state.updateParkingInfo)
  const { loadingUpdateParking, errorUpdateParking, updateParkingData } =
    updateParkingInfo

  useEffect(() => {
    dispatch({ type: UPDATE_PARKING_RESET })
    if (updateParkingData) {
      closeAddParking()
      toast(updateParkingData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getParkings())
      }, 1000)
    } else if (errorUpdateParking) {
      toast(errorUpdateParking, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateParkingData, errorUpdateParking])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Parkings</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={
                adminInfo.loginType === 'Valet'
                  ? () => {
                      setStatus('Add')
                      setAddParkingModal(true)
                    }
                  : () => history.push('/parkings/add')
              }
              text='Add a Parking'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Parking' />
        {addParkingModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add Parking</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Name *'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Address *'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Contract Started On *'
                        type='date'
                        value={contractStartedOn}
                        onChange={(e) => setContractStartedOn(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Contract Finished On *'
                        type='date'
                        value={contractFinishedOn}
                        onChange={(e) => setContractFinishedOn(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='GST'
                        value={gst}
                        onChange={(e) => setGst(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Driver Limit'
                        type='number'
                        value={driverLimit}
                        onChange={(e) => setDriverLimit(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Image *'
                        type='file'
                        // value={image}
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                      <Input
                        width='flex-1'
                        name='Rate *'
                        type='number'
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                      />
                    </div>
                    <Input
                      width='flex-1 w-full mb-4'
                      name='Basements *'
                      value={basements}
                      onChange={(e) => setBasements(e.target.value)}
                    />
                    <div className='flex flex-row gap-2 mb-4'>
                      <label class='w-1/2 text-gray-500 font-bold mt-2'>
                        <input
                          class='mr-2 leading-tight'
                          type='checkbox'
                          onChange={(e) =>
                            e.target.checked === true
                              ? setFoc(true)
                              : setFoc(false)
                          }
                          checked={foc === true ? true : false}
                        />
                        <span class='text-sm'>FOC</span>
                      </label>
                      <label class='w-1/2 text-gray-500 font-bold mt-2'>
                        <input
                          class='mr-2 leading-tight'
                          type='checkbox'
                          onChange={(e) =>
                            e.target.checked === true
                              ? setLpr(true)
                              : setLpr(false)
                          }
                          checked={lpr === true ? true : false}
                        />
                        <span class='text-sm'>LPR</span>
                      </label>
                    </div>
                    <div className='flex flex-row gap-2 mb-4'>
                      <label class='w-1/2 text-gray-500 font-bold mt-2'>
                        <input
                          class='mr-2 leading-tight'
                          type='checkbox'
                          onChange={(e) =>
                            e.target.checked === true
                              ? setShowMobile(true)
                              : setShowMobile(false)
                          }
                          checked={showMobile === true ? true : false}
                        />
                        <span class='text-sm'>Show Mobile</span>
                      </label>
                      <label class='w-1/2 text-gray-500 font-bold mt-2'>
                        <input
                          class='mr-2 leading-tight'
                          type='checkbox'
                          onChange={(e) =>
                            e.target.checked === true
                              ? setOnlinePayment(true)
                              : setOnlinePayment(false)
                          }
                          checked={onlinePayment === true ? true : false}
                        />
                        <span class='text-sm'>Online Payment</span>
                      </label>
                    </div>
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddParking}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={
                        status === 'Add'
                          ? submitAddParkingHandler
                          : submitUpdateParkingHandler
                      }
                      disabled={loadingAddParking || loadingUpdateParking}
                    >
                      {status === 'Add' ? 'Add' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Parking
