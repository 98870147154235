import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Select from '../components/Select'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { getAdmins, addAdmin, updateAdmin } from '../actions/adminActions'
import { getParkings } from '../actions/parkingActions'
import {
  ADD_ADMIN_RESET,
  GET_ADMINS_RESET,
  UPDATE_ADMIN_RESET,
} from '../constants/adminConstants'
import { GET_PARKINGS_RESET } from '../constants/parkingConstants'

const Admin = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addAdminModal, setAddAdminModal] = useState(false)
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('')
  const [isActive, setIsActive] = useState('')
  const [passwordModal, setPasswordModal] = useState(false)
  const [parking, setParking] = useState([])
  const [parkings, setParkings] = useState([])
  const [updateModal, setUpdateModal] = useState(false)
  const [adminToUpdate, setAdminToUpdate] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getAdmins())
    dispatch(getParkings())
  }, [])

  const roles =
    adminInfo.loginType === 'Valet'
      ? [
          { id: 'Super Admin', title: 'Super Admin' },
          { id: 'Admin', title: 'Admin' },
          { id: 'Vendor', title: 'Vendor' },
          { id: 'Facility', title: 'Facility' },
        ]
      : [
          { id: 'Super Admin', title: 'Super Admin' },
          { id: 'Admin', title: 'Admin' },
          { id: 'Vendor', title: 'Vendor' },
        ]

  // * Get Users
  const getAdminsInfo = useSelector((state) => state.getAdminsInfo)
  const { errorGetAdmins, getAdminsData } = getAdminsInfo

  useEffect(() => {
    dispatch({ type: GET_ADMINS_RESET })
    if (getAdminsData) {
      setLoading(false)
      setData(getAdminsData)
    } else if (errorGetAdmins) {
      setLoading(false)
      toast(errorGetAdmins, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getAdminsData, errorGetAdmins])

  // * Get Parkings
  const getParkingsInfo = useSelector((state) => state.getParkingsInfo)
  const { errorGetParkings, getParkingsData } = getParkingsInfo

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET })
    if (getParkingsData) {
      setLoading(false)
      setParkings(getParkingsData)
    } else if (errorGetParkings) {
      setLoading(false)
      toast(errorGetParkings, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getParkingsData, errorGetParkings])

  const closeAddAdminModal = () => {
    setUsername('')
    setName('')
    setRole('')
    setParking([])
    setAddAdminModal(false)
  }

  const addAdminHandler = () => {
    if (!username || !name || !role || parking.length === 0) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(addAdmin(username, name, role, parking))
    }
  }

  const addAdminInfo = useSelector((state) => state.addAdminInfo)
  const { loadingAddAdmin, addAdminData, errorAddAdmin } = addAdminInfo

  useEffect(() => {
    dispatch({ type: ADD_ADMIN_RESET })
    if (addAdminData) {
      setPassword(addAdminData)
      setPasswordModal(true)
      closeAddAdminModal()
      toast('Admin added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else if (errorAddAdmin) {
      toast(errorAddAdmin, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addAdminData, errorAddAdmin])

  const closePasswordModal = () => {
    setPassword('')
    setPasswordModal(false)
    setTimeout(() => {
      dispatch(getAdmins())
    }, 1000)
  }

  const removeItemsFromArray = (id) => {
    let newArray = parking.filter((item) => item !== id)
    setParking(newArray)
  }

  const updatedAdmin = (item) => {
    setUpdateModal(true)
    setAdminToUpdate(item._id)
    setName(item.name)
    setRole(item.role)
    setIsActive(item.isActive)

    let docs = []
    item.parkings.forEach((id) => {
      docs.push(id.toString())
    })

    setParking(docs)
  }

  const closeUpdateAdminModal = () => {
    setAdminToUpdate('')
    setName('')
    setRole('')
    setIsActive('')
    setParking([])
    setUpdateModal(false)
  }

  const updateAdminHandler = () => {
    if (adminInfo.loginType === 'Valet') {
      if (
        !adminToUpdate ||
        isActive === '' ||
        !name ||
        !role ||
        parking.length === 0
      ) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        dispatch(updateAdmin(adminToUpdate, isActive, name, role, parking))
      }
    } else {
      if (!adminToUpdate || isActive === '' || !name || !role) {
        toast('All the fields are mandatory', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
      } else {
        dispatch(updateAdmin(adminToUpdate, isActive, name, role))
      }
    }
  }

  const updateAdminInfo = useSelector((state) => state.updateAdminInfo)
  const { loadingUpdateAdmin, updateAdminData, errorUpdateAdmin } =
    updateAdminInfo

  useEffect(() => {
    dispatch({ type: UPDATE_ADMIN_RESET })
    if (updateAdminData) {
      closeUpdateAdminModal()
      toast(updateAdminData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getAdmins())
      }, 1000)
    } else if (errorUpdateAdmin) {
      toast(errorUpdateAdmin, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateAdminData, errorUpdateAdmin])

  const headCells = [
    {
      field: 'username',
      title: 'Username',
      render: (rowData) => {
        return (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => updatedAdmin(rowData)}
          >
            {rowData.username}
          </div>
        )
      },
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'role',
      title: 'Role',
    },
    {
      field: 'isActive',
      title: 'Is Active?',
      render: (rowData) => {
        return <p>{rowData.isActive === true ? 'Yes' : 'No'}</p>
      },
    },
  ]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Admins</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => setAddAdminModal(true)}
              text='Add an Admin'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Admin' />
        {addAdminModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add Admin</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Username *'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Name *'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <Select
                      width='w-full mb-4'
                      name='Role *'
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={roles}
                    />
                    <p className='mb-2 text-sm'>Select Parkings *</p>
                    <div class='flex flex-wrap md:items-center mb-4  h-48 overflow-y-auto'>
                      {parkings.map((item) => (
                        <label class='w-1/2 text-gray-500 font-bold mt-2'>
                          <input
                            class='mr-2 leading-tight'
                            type='checkbox'
                            onChange={(e) =>
                              e.target.checked === true
                                ? setParking([...parking, item._id])
                                : removeItemsFromArray(item._id)
                            }
                          />
                          <span class='text-sm'>
                            {adminInfo.loginType === 'Valet'
                              ? item.name
                              : item.managerAppName}
                          </span>
                        </label>
                      ))}
                    </div>
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddAdminModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addAdminHandler}
                      disabled={loadingAddAdmin}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {passwordModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Password</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='flex-1'
                      name='Password *'
                      value={password}
                      onChange={null}
                      disabled
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closePasswordModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {updateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Update Admin</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='w-full mb-4'
                      name='Name *'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Select
                      width='w-full mb-4'
                      name='Role *'
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={roles}
                    />
                    <Select
                      width='w-full mb-4'
                      name='Is Active *'
                      value={isActive}
                      onChange={(e) => setIsActive(e.target.value)}
                      options={[
                        { id: true, title: 'Yes' },
                        { id: false, title: 'No' },
                      ]}
                    />
                    <>
                      <p className='mb-2 text-sm'>Select Parkings *</p>
                      <div class='flex flex-wrap md:items-center mb-4  h-48 overflow-y-auto'>
                        {parkings.map((item) => (
                          <label class='w-1/2 text-gray-500 font-bold mt-2'>
                            <input
                              class='mr-2 leading-tight'
                              type='checkbox'
                              onChange={(e) =>
                                e.target.checked === true
                                  ? setParking([...parking, item._id])
                                  : removeItemsFromArray(item._id)
                              }
                              checked={
                                parking.includes(item._id) ? true : false
                              }
                            />
                            <span class='text-sm mr-10'>
                              {adminInfo.loginType === 'Valet'
                                ? item.name
                                : item.managerAppName}
                            </span>
                          </label>
                        ))}
                      </div>
                    </>
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeUpdateAdminModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={updateAdminHandler}
                      disabled={loadingUpdateAdmin}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Admin
