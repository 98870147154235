export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const GET_USERS_RESET = 'GET_USERS_RESET'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const ADD_USER_RESET = 'ADD_USER_RESET'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'

export const RESET_DEVICE_ID_USER_REQUEST = 'RESET_DEVICE_ID_USER_REQUEST'
export const RESET_DEVICE_ID_USER_SUCCESS = 'RESET_DEVICE_ID_USER_SUCCESS'
export const RESET_DEVICE_ID_USER_FAIL = 'RESET_DEVICE_ID_USER_FAIL'
export const RESET_DEVICE_ID_USER_RESET = 'RESET_DEVICE_ID_USER_RESET'

export const NEW_PASSWORD_USER_REQUEST = 'NEW_PASSWORD_USER_REQUEST'
export const NEW_PASSWORD_USER_SUCCESS = 'NEW_PASSWORD_USER_SUCCESS'
export const NEW_PASSWORD_USER_FAIL = 'NEW_PASSWORD_USER_FAIL'
export const NEW_PASSWORD_USER_RESET = 'NEW_PASSWORD_USER_RESET'
