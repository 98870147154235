import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from './screens/Login'
import SideBar from './components/SideBar'
import Parking from './screens/Parking'
import Container from './components/Container'
import Admin from './screens/Admin'
import User from './screens/User'
import Checkin from './screens/Checkin'
import Location from './screens/Location'
import Vehicle from './screens/Vehicle'
import AddParking from './screens/AddParking'
import Pass from './screens/Pass'
import CheckinUnorganised from './screens/CheckinUnorganised'
import { useSelector } from 'react-redux'
import UpdateParking from './screens/UpdateParking'
import Rate from './screens/Rate'
import AddRate from './screens/AddRate'

function App() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path='/' component={Login} exact />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  )
}

const DefaultContainer = () => {
  // * Get logged in user's info
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  return (
    <Container>
      <SideBar />
      <div className='mx-auto py-10 px-6 bg-gray-100 overflow-x-auto w-4/5'>
        <Route path='/admins' component={Admin} exact />
        <Route path='/parkings' component={Parking} exact />
        <Route path='/parkings/add' component={AddParking} exact />
        <Route path='/parking/:id' component={UpdateParking} />
        <Route path='/users' component={User} exact />
        <Route
          path='/checkins'
          component={
            adminInfo && adminInfo.loginType === 'Valet'
              ? Checkin
              : CheckinUnorganised
          }
          exact
        />
        <Route path='/locations' component={Location} exact />
        <Route path='/vehicles' component={Vehicle} exact />
        <Route path='/passes' component={Pass} exact />
        <Route path='/rates' component={Rate} exact />
        <Route path='/rates/add' component={AddRate} exact />
        <Route path='/rate/:id' component={AddRate} exact />
      </div>
    </Container>
  )
}

export default App
