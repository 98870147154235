import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  addLocation,
  getLocations,
  updateLocation,
} from '../actions/locationActions'
import Button from '../components/Button'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import {
  ADD_LOCATION_RESET,
  GET_LOCATIONS_RESET,
  UPDATE_LOCATION_RESET,
} from '../constants/locationConstants'

const Location = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addLocationModal, setAddLocationModal] = useState(false)
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [contractStartedOn, setContractStartedOn] = useState(new Date())
  const [contractFinishedOn, setContractFinishedOn] = useState(new Date())
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [id, setId] = useState('')
  const [type, setType] = useState('')
  const [carInventory, setCarInventory] = useState(0)
  const [bikeInventory, setBikeInventory] = useState(0)
  const [rateToDisplay, setRateToDisplay] = useState('')
  const [note, setNote] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [showOnApp, setShowOnApp] = useState(false)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    } else {
      if (adminInfo.loginType !== 'Unorganised') {
        history.push('/')
      }
    }
  }, [adminInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getLocations())
  }, [])

  // * Get Locations
  const getLocationsInfo = useSelector((state) => state.getLocationsInfo)
  const { errorGetLocations, getLocationsData } = getLocationsInfo

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_RESET })
    if (getLocationsData) {
      setLoading(false)
      setData(getLocationsData)
    } else if (errorGetLocations) {
      setLoading(false)
      toast(errorGetLocations, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getLocationsData, errorGetLocations])

  const closeAddLocationModal = () => {
    setType('')
    setAddLocationModal(false)
    setName('')
    setAddress('')
    setContractStartedOn(new Date())
    setContractFinishedOn(new Date())
    setLatitude(0.0)
    setLongitude(0.0)
    setId('')
  }

  const addLocationHandler = () => {
    if (
      !name ||
      !address ||
      !contractStartedOn ||
      !contractFinishedOn ||
      !latitude ||
      !longitude ||
      !rateToDisplay ||
      !displayName
    ) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      // Inventory check
      let inventory = []
      if (Number(carInventory) > 0) {
        inventory.push({ type: 'car', total: Number(carInventory) })
      }
      if (Number(bikeInventory) > 0) {
        inventory.push({ type: 'bike', total: Number(bikeInventory) })
      }

      dispatch(
        addLocation(
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          latitude,
          longitude,
          inventory,
          rateToDisplay,
          note,
          displayName,
          showOnApp
        )
      )
    }
  }

  // * Add Location
  const addLocationInfo = useSelector((state) => state.addLocationInfo)
  const { loadingAddLocation, errorAddLocation, addLocationData } =
    addLocationInfo

  useEffect(() => {
    dispatch({ type: ADD_LOCATION_RESET })
    if (addLocationData) {
      closeAddLocationModal()
      toast(addLocationData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getLocations())
      }, 1000)
    } else if (errorAddLocation) {
      toast(errorAddLocation, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addLocationData, errorAddLocation])

  const updatedLocation = (item) => {
    setId(item._id)
    setType('Update')
    setAddLocationModal(true)
    setName(item.name)
    setAddress(item.address)
    setContractStartedOn(moment(item.contractStartedOn).format('YYYY-MM-DD'))
    setContractFinishedOn(moment(item.contractFinishedOn).format('YYYY-MM-DD'))
    setLatitude(item.latitude)
    setLongitude(item.longitude)
    let car = item.inventory.find((item) => {
      return item.type === 'car'
    })
    setCarInventory(car ? car.total : 0)
    let bike = item.inventory.find((item) => {
      return item.type === 'bike'
    })
    setBikeInventory(bike ? bike.total : 0)
    setRateToDisplay(item.rateToDisplay)
    setNote(item.note)
    setDisplayName(item.displayName)
    setShowOnApp(item.showOnApp)
  }

  const updateLocationHandler = () => {
    if (
      !id ||
      !name ||
      !address ||
      !contractStartedOn ||
      !contractFinishedOn ||
      !latitude ||
      !longitude ||
      !rateToDisplay ||
      !displayName
    ) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      // Inventory check
      let inventory = []
      if (Number(carInventory) > 0) {
        inventory.push({ type: 'car', total: Number(carInventory) })
      }
      if (Number(bikeInventory) > 0) {
        inventory.push({ type: 'bike', total: Number(bikeInventory) })
      }

      dispatch(
        updateLocation(
          id,
          name,
          address,
          contractStartedOn,
          contractFinishedOn,
          latitude,
          longitude,
          inventory,
          rateToDisplay,
          note,
          displayName,
          showOnApp
        )
      )
    }
  }

  // * Update Location
  const updateLocationInfo = useSelector((state) => state.updateLocationInfo)
  const { loadingUpdateLocation, errorUpdateLocation, updateLocationData } =
    updateLocationInfo

  useEffect(() => {
    dispatch({ type: UPDATE_LOCATION_RESET })
    if (updateLocationData) {
      closeAddLocationModal()
      toast(updateLocationData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getLocations())
      }, 1000)
    } else if (errorUpdateLocation) {
      toast(errorUpdateLocation, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateLocationData, errorUpdateLocation])

  const headCells = [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => {
        return (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => updatedLocation(rowData)}
          >
            {rowData.name}
          </div>
        )
      },
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'contractStartedOn',
      title: 'Contract Started On',
    },
    {
      field: 'contractFinishedOn',
      title: 'Contract Finished On',
    },
    {
      field: 'latitude',
      title: 'Latitude',
    },
    {
      field: 'longitude',
      title: 'Longitude',
    },
  ]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Locations</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => {
                setType('Add')
                setAddLocationModal(true)
              }}
              text='Add a Location'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Location' />
        {addLocationModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>
                      {type === 'Add' ? 'Add' : 'Update'} Location
                    </h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Name *'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Address *'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Contract Started On *'
                        type='date'
                        value={contractStartedOn}
                        onChange={(e) => setContractStartedOn(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Contract Finished On *'
                        type='date'
                        value={contractFinishedOn}
                        onChange={(e) => setContractFinishedOn(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Latitude *'
                        type='number'
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Longitude *'
                        type='number'
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </div>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Display Name (User app) *'
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Rate to Display (User app) *'
                        value={rateToDisplay}
                        onChange={(e) => setRateToDisplay(e.target.value)}
                      />
                    </div>
                    <Input
                      width='w-full mb-4'
                      name='Note (User app) *'
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Car Inventory (User app) *'
                        type='number'
                        value={carInventory}
                        onChange={(e) => setCarInventory(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Bike Inventory (User app) *'
                        type='number'
                        value={bikeInventory}
                        onChange={(e) => setBikeInventory(e.target.value)}
                      />
                    </div>
                    <div className='w-full mb-4 flex flex-row items-center gap-4'>
                      <input
                        type='checkbox'
                        value={showOnApp}
                        checked={showOnApp}
                        onChange={(e) => setShowOnApp(e.target.checked)}
                      />
                      <p className='text-sm'>Show on the user application?</p>
                    </div>
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddLocationModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={
                        type === 'Add'
                          ? addLocationHandler
                          : updateLocationHandler
                      }
                      disabled={loadingAddLocation || loadingUpdateLocation}
                    >
                      {type === 'Add' ? 'Add' : 'Update'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Location
