import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import Button from '../components/Button'
import Input from '../components/Input'
import Select from '../components/Select'
import { getVehicles } from '../actions/vehicleActions'
import { GET_VEHICLES_RESET } from '../constants/vehicleConstants'
import { addRate, updateRate } from '../actions/rateActions'
import { getRateById } from '../actions/rateActions'
import {
  ADD_RATE_RESET,
  GET_RATE_BY_ID_RESET,
  UPDATE_RATE_RESET,
} from '../constants/rateConstants'

const AddRate = ({ history }) => {
  // * Initialization
  const dispatch = useDispatch()

  let params = useParams()
  let id = ''
  if (params.id) {
    id = params.id.replace(':', '')
    if (id === 'add') {
      id = ''
    }
  }

  // States
  const [vehicles, setVehicles] = useState([])
  const [name, setName] = useState('')
  const [rateList, setRateList] = useState([])
  const [car, setCar] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [bike, setBike] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [cycle, setCycle] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [tempo, setTempo] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [truck, setTruck] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [bus, setBus] = useState({ type: null, rate: null, checkinInfo: null })
  const [auto, setAuto] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [staffcar, setStaffCar] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [premiumcar, setPremiumcar] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [staffbike, setStaffbike] = useState({
    type: null,
    rate: null,
    checkinInfo: null,
  })
  const [dataFromServer, setDataFromServer] = useState(null)

  // * Check for auth
  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    // * Check if user info exists
    if (!adminInfo) {
      history.push('/')
    } else {
      if (adminInfo.loginType !== 'Unorganised') {
        history.push('/')
      }
    }
  }, [adminInfo, history])

  useEffect(() => {
    dispatch(getVehicles())
    if (id) {
      dispatch(getRateById(id))
    }
  }, [id])

  // * Get Vehicles
  const getVehiclesInfo = useSelector((state) => state.getVehiclesInfo)
  const { errorGetVehicles, getVehiclesData } = getVehiclesInfo

  useEffect(() => {
    dispatch({ type: GET_VEHICLES_RESET })
    if (getVehiclesData) {
      setVehicles(getVehiclesData)
    } else if (errorGetVehicles) {
      toast(errorGetVehicles, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getVehiclesData, errorGetVehicles])

  // Get Rate by Id
  const getRateByIdInfo = useSelector((state) => state.getRateByIdInfo)
  const { loadingGetRateById, getRateByIdData, errorGetRateById } =
    getRateByIdInfo

  useEffect(() => {
    dispatch({ type: GET_RATE_BY_ID_RESET })
    if (getRateByIdData) {
      // Name
      setName(getRateByIdData.name)
      setDataFromServer(getRateByIdData)
    } else if (errorGetRateById) {
      toast(errorGetRateById, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getRateByIdData, errorGetRateById])

  useEffect(() => {
    if (vehicles.length > 0 && dataFromServer) {
      dataFromServer.rateList.map((item) => {
        const vehicleFound = vehicles.find(
          (obj) => obj._id.toString() === item.vehicleType
        )

        const rates = getRateAndConvert(item)
        item['rate'] = JSON.stringify(rates)

        if (vehicleFound?.name === 'car') {
          setCar(item)
        } else if (vehicleFound?.name === 'bike') {
          setBike(item)
        } else if (vehicleFound?.name === 'cycle') {
          setCycle(item)
        } else if (vehicleFound?.name === 'tempo') {
          setTempo(item)
        } else if (vehicleFound?.name === 'truck') {
          setTruck(item)
        } else if (vehicleFound?.name === 'bus') {
          setBus(item)
        } else if (vehicleFound?.name === 'auto') {
          setAuto(item)
        } else if (vehicleFound?.name === 'staffcar') {
          setStaffCar(item)
        } else if (vehicleFound?.name === 'premiumcar') {
          setPremiumcar(item)
        } else if (vehicleFound?.name === 'staffbike') {
          setStaffbike(item)
        }
      })
    }
  }, [vehicles, dataFromServer])

  // Get rate from server object
  const getRateAndConvert = (doc) => {
    const docRate = {}

    for (const prop in doc) {
      if (
        prop !== 'vehicleType' &&
        prop !== 'type' &&
        prop !== 'night' &&
        prop !== 'checkinInfo' &&
        prop !== 'dayDaily' &&
        prop !== 'dayNightDaily' &&
        prop !== 'dayWeekly' &&
        prop !== 'dayNightWeekly' &&
        prop !== 'dayMonthly' &&
        prop !== 'dayNightMonthly'
      ) {
        docRate[prop] = doc[prop]
      }
    }

    return docRate
  }

  // Validate vehicle
  const validate = (vehicleType) => {
    const vehicleFound = vehicles.find((item) => item.name === vehicleType)

    let obj = {}

    let objSelected = {}
    // Essentials check
    if (vehicleFound.name === 'car') {
      objSelected = car
    } else if (vehicleFound.name === 'bike') {
      objSelected = bike
    } else if (vehicleFound.name === 'cycle') {
      objSelected = cycle
    } else if (vehicleFound.name === 'tempo') {
      objSelected = tempo
    } else if (vehicleFound.name === 'truck') {
      objSelected = truck
    } else if (vehicleFound.name === 'bus') {
      objSelected = bus
    } else if (vehicleFound.name === 'auto') {
      objSelected = auto
    } else if (vehicleFound.name === 'staffcar') {
      objSelected = staffcar
    } else if (vehicleFound.name === 'premiumcar') {
      objSelected = premiumcar
    } else if (vehicleFound.name === 'staffbike') {
      objSelected = staffbike
    } else {
      toast('Invalid vehicle type selected', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return false
    }

    if (
      !objSelected ||
      !objSelected.type ||
      !objSelected.rate ||
      !objSelected.checkinInfo ||
      !vehicleFound
    ) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return false
    }

    // JSON validation
    const result = isJsonString(objSelected.rate)
    if (!result) {
      toast('Invalid rate, please try again', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return false
    }

    obj.vehicleType = vehicleFound._id
    obj.type = objSelected.type
    obj.checkinInfo = objSelected.checkinInfo
    if (objSelected.night) obj.night = objSelected.night
    if (objSelected.holiday) obj.holiday = objSelected.holiday
    if (objSelected.dayDaily) obj.dayDaily = objSelected.dayDaily
    if (objSelected.dayNightDaily) obj.dayNightDaily = objSelected.dayNightDaily
    if (objSelected.dayWeekly) obj.dayWeekly = objSelected.dayWeekly
    if (objSelected.dayNightWeekly)
      obj.dayNightDaily = objSelected.dayNightDaily
    if (objSelected.dayMonthly) obj.dayMonthly = objSelected.dayMonthly
    if (objSelected.dayNightMonthly)
      obj.dayNightMonthly = objSelected.dayNightMonthly
    if (objSelected.passInfo) obj.passInfo = objSelected.passInfo

    const rateToLoop = JSON.parse(objSelected.rate)

    Object.keys(rateToLoop).forEach(function (key) {
      if (rateToLoop[key]) {
        obj[key] = Number(rateToLoop[key])
      }
    })

    // Check if this vehicle already exists in rate list arr
    const vehicleFoundInRateList = rateList.filter((item) => {
      return item.vehicleType !== vehicleFound._id
    })

    vehicleFoundInRateList.push(obj)

    setRateList(vehicleFoundInRateList)

    toast('Rate validation successfully', {
      type: 'success',
      hideProgressBar: true,
      autoClose: 2000,
    })
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  // Add Rate
  const submitHandler = () => {
    if (!name || rateList.length === 0) {
      toast('Name and atleast 1 vehicle rate is required', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      if (id) {
        dispatch(updateRate(id, rateList))
      } else {
        dispatch(addRate(name, rateList))
      }
    }
  }

  const addRateInfo = useSelector((state) => state.addRateInfo)
  const { loadingAddRate, addRateData, errorAddRate } = addRateInfo

  useEffect(() => {
    dispatch({ type: ADD_RATE_RESET })
    if (addRateData) {
      toast('Rate added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      history.push('/rates')
    } else if (errorAddRate) {
      toast(errorAddRate, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addRateData, errorAddRate])

  const updateRateInfo = useSelector((state) => state.updateRateInfo)
  const { loadingUpdateRate, updateRateData, errorUpdateRate } = updateRateInfo

  useEffect(() => {
    dispatch({ type: UPDATE_RATE_RESET })
    if (updateRateData) {
      toast('Rate updated successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      history.push('/rates')
    } else if (errorUpdateRate) {
      toast(errorUpdateRate, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateRateData, errorUpdateRate])

  return (
    <div className='w-full h-full'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-2xl font-semibold'>Add Rate</h1>
        {rateList.length > 0 && (
          <Button
            custom='py-2 mt-4 md:mt-0 bg-green-500 hover:bg-green-700'
            type='button'
            onClick={submitHandler}
            text='Submit'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        )}
      </div>
      <div className='bg-white shadow-md rounded px-6 py-6 my-4 flex flex-col gap-4'>
        <h3>Name</h3>
        <Input
          width='flex-1'
          name='Name *'
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={loadingAddRate || loadingUpdateRate}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <p className='text-sm'>
          Note: There are 2 types of rates i.e. hourly or daily, an example for
          both is shown below
        </p>
        <p className='text-sm font-bold'>Daily</p>
        <p className='text-sm'>
          &#123; monday: 50, tuesday: 100, wednesday: 50, thursday: 20, friday:
          100, saturday: 200, sunday: 200 &#125;
        </p>
        <p className='text-sm font-bold'>Hourly</p>
        <p className='text-sm'>
          If there is a flat rate then type 0above, if it is recurring then type
          every and then the minutes like every60 else type time range in
          minutes like 0-60, 61-120
        </p>
        <p className='text-sm'>
          &#123; 0-60: 100, 61-120: 200, every60: 200 &#125;
        </p>
      </div>
      <div className='flex flex-wrap gap-6 my-6 w-full justify-center'>
        {/* Car */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Car</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={car && car.type}
              onChange={(e) => setCar({ ...car, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={car && car.rate}
              onChange={(e) => setCar({ ...car, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={car ? car.checkinInfo : ''}
              onChange={(e) => setCar({ ...car, checkinInfo: e.target.value })}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={car ? car.night : 0}
                onChange={(e) => setCar({ ...car, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={car ? car.holiday : 0}
                onChange={(e) => setCar({ ...car, holiday: e.target.value })}
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={car ? car.dayDaily : 0}
                onChange={(e) => setCar({ ...car, dayDaily: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={car ? car.dayNightDaily : 0}
                onChange={(e) =>
                  setCar({ ...car, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={car ? car.dayWeekly : 0}
                onChange={(e) => setCar({ ...car, dayWeekly: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={car ? car.dayNightWeekly : 0}
                onChange={(e) =>
                  setCar({ ...car, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={car ? car.dayMonthly : 0}
                onChange={(e) => setCar({ ...car, dayMonthly: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={car ? car.dayNightMonthly : 0}
                onChange={(e) =>
                  setCar({ ...car, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={car ? car.passInfo : ''}
              onChange={(e) => setCar({ ...car, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('car')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Bike */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Bike</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={bike && bike.type}
              onChange={(e) => setBike({ ...bike, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={bike && bike.rate}
              onChange={(e) => setBike({ ...bike, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={bike ? bike.checkinInfo : ''}
              onChange={(e) =>
                setBike({ ...bike, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={bike ? bike.night : 0}
                onChange={(e) => setBike({ ...bike, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={bike ? bike.holiday : 0}
                onChange={(e) => setBike({ ...bike, holiday: e.target.value })}
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={bike ? bike.dayDaily : 0}
                onChange={(e) => setBike({ ...bike, dayDaily: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={bike ? bike.dayNightDaily : 0}
                onChange={(e) =>
                  setBike({ ...bike, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={bike ? bike.dayWeekly : 0}
                onChange={(e) =>
                  setBike({ ...bike, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={bike ? bike.dayNightWeekly : 0}
                onChange={(e) =>
                  setBike({ ...bike, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={bike ? bike.dayMonthly : 0}
                onChange={(e) =>
                  setBike({ ...bike, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={bike ? bike.dayNightMonthly : 0}
                onChange={(e) =>
                  setBike({ ...bike, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={bike ? bike.passInfo : ''}
              onChange={(e) => setBike({ ...bike, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('bike')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Cycle */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Cycle</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={cycle && cycle.type}
              onChange={(e) => setCycle({ ...cycle, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={cycle && cycle.rate}
              onChange={(e) => setCycle({ ...cycle, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={cycle ? cycle.checkinInfo : ''}
              onChange={(e) =>
                setCycle({ ...cycle, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={cycle ? cycle.night : 0}
                onChange={(e) => setCycle({ ...cycle, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={cycle ? cycle.holiday : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={cycle ? cycle.dayDaily : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={cycle ? cycle.dayNightDaily : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={cycle ? cycle.dayWeekly : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={cycle ? cycle.dayNightWeekly : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={cycle ? cycle.dayMonthly : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={cycle ? cycle.dayNightMonthly : 0}
                onChange={(e) =>
                  setCycle({ ...cycle, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={cycle ? cycle.passInfo : ''}
              onChange={(e) => setCycle({ ...cycle, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('cycle')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Tempo */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Tempo</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={tempo && tempo.type}
              onChange={(e) => setTempo({ ...tempo, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={tempo && tempo.rate}
              onChange={(e) => setTempo({ ...tempo, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={tempo ? tempo.checkinInfo : ''}
              onChange={(e) =>
                setTempo({ ...tempo, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={tempo ? tempo.night : 0}
                onChange={(e) => setTempo({ ...tempo, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={tempo ? tempo.holiday : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={tempo ? tempo.dayDaily : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={tempo ? tempo.dayNightDaily : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={tempo ? tempo.dayWeekly : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={tempo ? tempo.dayNightWeekly : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={tempo ? tempo.dayMonthly : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={tempo ? tempo.dayNightMonthly : 0}
                onChange={(e) =>
                  setTempo({ ...tempo, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={tempo ? tempo.passInfo : ''}
              onChange={(e) => setTempo({ ...tempo, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('tempo')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Truck */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Truck</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={truck && truck.type}
              onChange={(e) => setTruck({ ...truck, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={truck && truck.rate}
              onChange={(e) => setTruck({ ...truck, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={truck ? truck.checkinInfo : ''}
              onChange={(e) =>
                setTruck({ ...truck, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={truck ? truck.night : 0}
                onChange={(e) => setTruck({ ...truck, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={truck ? truck.holiday : 0}
                onChange={(e) =>
                  setTruck({ ...truck, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={truck ? truck.dayDaily : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={truck ? truck.dayNightDaily : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={truck ? truck.dayWeekly : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={truck ? truck.dayNightWeekly : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={truck ? truck.dayMonthly : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={truck ? truck.dayNightMonthly : 0}
                onChange={(e) =>
                  setTruck({ ...truck, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={truck ? truck.passInfo : ''}
              onChange={(e) => setTruck({ ...truck, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('truck')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Bus */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Bus</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={bus && bus.type}
              onChange={(e) => setBus({ ...bus, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={bus && bus.rate}
              onChange={(e) => setBus({ ...bus, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={bus ? bus.checkinInfo : ''}
              onChange={(e) => setBus({ ...bus, checkinInfo: e.target.value })}
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={bus ? bus.night : 0}
                onChange={(e) => setBus({ ...bus, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={bus ? bus.holiday : 0}
                onChange={(e) => setBus({ ...bus, holiday: e.target.value })}
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={bus ? bus.dayDaily : 0}
                onChange={(e) => setBus({ ...bus, dayDaily: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={bus ? bus.dayNightDaily : 0}
                onChange={(e) =>
                  setBus({ ...bus, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={bus ? bus.dayWeekly : 0}
                onChange={(e) => setBus({ ...bus, dayWeekly: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={bus ? bus.dayNightWeekly : 0}
                onChange={(e) =>
                  setBus({ ...bus, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={bus ? bus.dayMonthly : 0}
                onChange={(e) => setBus({ ...bus, dayMonthly: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={bus ? bus.dayNightMonthly : 0}
                onChange={(e) =>
                  setBus({ ...bus, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={bus ? bus.passInfo : ''}
              onChange={(e) => setBus({ ...bus, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('bus')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Auto */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Auto</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={auto && auto.type}
              onChange={(e) => setAuto({ ...auto, type: e.target.value })}
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={auto && auto.rate}
              onChange={(e) => setAuto({ ...auto, rate: e.target.value })}
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={auto ? auto.checkinInfo : ''}
              onChange={(e) =>
                setAuto({ ...auto, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={auto ? auto.night : 0}
                onChange={(e) => setAuto({ ...auto, night: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={auto ? auto.holiday : 0}
                onChange={(e) => setAuto({ ...auto, holiday: e.target.value })}
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={auto ? auto.dayDaily : 0}
                onChange={(e) => setAuto({ ...auto, dayDaily: e.target.value })}
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={auto ? auto.dayNightDaily : 0}
                onChange={(e) =>
                  setAuto({ ...auto, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={auto ? auto.dayWeekly : 0}
                onChange={(e) =>
                  setAuto({ ...auto, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={auto ? auto.dayNightWeekly : 0}
                onChange={(e) =>
                  setAuto({ ...auto, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={auto ? auto.dayMonthly : 0}
                onChange={(e) =>
                  setAuto({ ...auto, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={auto ? auto.dayNightMonthly : 0}
                onChange={(e) =>
                  setAuto({ ...auto, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={auto ? auto.passInfo : ''}
              onChange={(e) => setAuto({ ...auto, passInfo: e.target.value })}
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('auto')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Staff Car */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Staff Car</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={staffcar && staffcar.type}
              onChange={(e) =>
                setStaffCar({ ...staffcar, type: e.target.value })
              }
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={staffcar && staffcar.rate}
              onChange={(e) =>
                setStaffCar({ ...staffcar, rate: e.target.value })
              }
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={staffcar ? staffcar.checkinInfo : ''}
              onChange={(e) =>
                setStaffCar({ ...staffcar, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={staffcar ? staffcar.night : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, night: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={staffcar ? staffcar.holiday : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={staffcar ? staffcar.dayDaily : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={staffcar ? staffcar.dayNightDaily : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayNightDaily: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={staffcar ? staffcar.dayWeekly : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={staffcar ? staffcar.dayNightWeekly : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayNightWeekly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={staffcar ? staffcar.dayMonthly : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={staffcar ? staffcar.dayNightMonthly : 0}
                onChange={(e) =>
                  setStaffCar({ ...staffcar, dayNightMonthly: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={staffcar ? staffcar.passInfo : ''}
              onChange={(e) =>
                setStaffCar({ ...staffcar, passInfo: e.target.value })
              }
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('staffcar')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Premium Car */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Premium Car</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={premiumcar && premiumcar.type}
              onChange={(e) =>
                setPremiumcar({ ...premiumcar, type: e.target.value })
              }
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={premiumcar && premiumcar.rate}
              onChange={(e) =>
                setPremiumcar({ ...premiumcar, rate: e.target.value })
              }
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={premiumcar ? premiumcar.checkinInfo : ''}
              onChange={(e) =>
                setPremiumcar({ ...premiumcar, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={premiumcar ? premiumcar.night : 0}
                onChange={(e) =>
                  setPremiumcar({ ...premiumcar, night: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={premiumcar ? premiumcar.holiday : 0}
                onChange={(e) =>
                  setPremiumcar({ ...premiumcar, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={premiumcar ? premiumcar.dayDaily : 0}
                onChange={(e) =>
                  setPremiumcar({ ...premiumcar, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={premiumcar ? premiumcar.dayNightDaily : 0}
                onChange={(e) =>
                  setPremiumcar({
                    ...premiumcar,
                    dayNightDaily: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={premiumcar ? premiumcar.dayWeekly : 0}
                onChange={(e) =>
                  setPremiumcar({ ...premiumcar, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={premiumcar ? premiumcar.dayNightWeekly : 0}
                onChange={(e) =>
                  setPremiumcar({
                    ...premiumcar,
                    dayNightWeekly: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={premiumcar ? premiumcar.dayMonthly : 0}
                onChange={(e) =>
                  setPremiumcar({ ...premiumcar, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={premiumcar ? premiumcar.dayNightMonthly : 0}
                onChange={(e) =>
                  setPremiumcar({
                    ...premiumcar,
                    dayNightMonthly: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={premiumcar ? premiumcar.passInfo : ''}
              onChange={(e) =>
                setPremiumcar({ ...premiumcar, passInfo: e.target.value })
              }
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('premiumcar')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
        {/* Staff Bike */}
        <div className='bg-white shadow-md rounded px-6 py-6 flex flex-col'>
          <h2 className='text-lg font-bold mb-4'>Staff Bike</h2>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Type *</p>
            <Select
              width='w-full'
              name='Type'
              value={staffbike && staffbike.type}
              onChange={(e) =>
                setStaffbike({ ...staffbike, type: e.target.value })
              }
              options={[
                { id: 'hourly', title: 'Hourly' },
                { id: 'daily', title: 'Daily' },
              ]}
            />
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate *</p>
            <textarea
              className='w-full
              block appearance-none bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
              rows={5}
              value={staffbike && staffbike.rate}
              onChange={(e) =>
                setStaffbike({ ...staffbike, rate: e.target.value })
              }
            ></textarea>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Rate to display ticket *</p>
            <Input
              width='flex-1'
              name='Checkin Info *'
              value={staffbike ? staffbike.checkinInfo : ''}
              onChange={(e) =>
                setStaffbike({ ...staffbike, checkinInfo: e.target.value })
              }
            />
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Night Charge</p>
              <Input
                width='flex-1'
                name='Night'
                value={staffbike ? staffbike.night : 0}
                onChange={(e) =>
                  setStaffbike({ ...staffbike, night: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Holiday mode flat rate</p>
              <Input
                width='flex-1'
                name='Holiday mode flat rate'
                value={staffbike ? staffbike.holiday : 0}
                onChange={(e) =>
                  setStaffbike({ ...staffbike, holiday: e.target.value })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day daily)'
                value={staffbike ? staffbike.dayDaily : 0}
                onChange={(e) =>
                  setStaffbike({ ...staffbike, dayDaily: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night daily)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night daily)'
                value={staffbike ? staffbike.dayNightDaily : 0}
                onChange={(e) =>
                  setStaffbike({
                    ...staffbike,
                    dayNightDaily: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day weekly)'
                value={staffbike ? staffbike.dayWeekly : 0}
                onChange={(e) =>
                  setStaffbike({ ...staffbike, dayWeekly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night weekly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night weekly)'
                value={staffbike ? staffbike.dayNightWeekly : 0}
                onChange={(e) =>
                  setStaffbike({
                    ...staffbike,
                    dayNightWeekly: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day monthly)'
                value={staffbike ? staffbike.dayMonthly : 0}
                onChange={(e) =>
                  setStaffbike({ ...staffbike, dayMonthly: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col gap-2 w-full mb-2'>
              <p className='text-sm'>Pass rate (Day night monthly)</p>
              <Input
                width='flex-1'
                name='Pass rate (Day night monthly)'
                value={staffbike ? staffbike.dayNightMonthly : 0}
                onChange={(e) =>
                  setStaffbike({
                    ...staffbike,
                    dayNightMonthly: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-full mb-2'>
            <p className='text-sm'>Pass rate to display ticket</p>
            <Input
              width='flex-1'
              name='Pass Info'
              value={staffbike ? staffbike.passInfo : ''}
              onChange={(e) =>
                setStaffbike({ ...staffbike, passInfo: e.target.value })
              }
            />
          </div>
          <Button
            custom='py-2 mt-2 bg-green-500 hover:bg-green-700 w-24'
            type='button'
            onClick={() => validate('staffbike')}
            text='Validate'
            disabled={loadingAddRate || loadingUpdateRate}
          />
        </div>
      </div>
    </div>
  )
}

export default AddRate
