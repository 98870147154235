import {
  ADD_PARKING_FAIL,
  ADD_PARKING_REQUEST,
  ADD_PARKING_RESET,
  ADD_PARKING_SUCCESS,
  GET_PARKINGS_FAIL,
  GET_PARKINGS_REQUEST,
  GET_PARKINGS_RESET,
  GET_PARKINGS_SUCCESS,
  GET_PARKING_BY_ID_FAIL,
  GET_PARKING_BY_ID_REQUEST,
  GET_PARKING_BY_ID_RESET,
  GET_PARKING_BY_ID_SUCCESS,
  UPDATE_PARKING_FAIL,
  UPDATE_PARKING_REQUEST,
  UPDATE_PARKING_RESET,
  UPDATE_PARKING_SUCCESS,
} from '../constants/parkingConstants'

export const getParkingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PARKINGS_REQUEST:
      return { loadingGetParkings: true }
    case GET_PARKINGS_SUCCESS:
      return { loadingGetParkings: false, getParkingsData: action.payload }
    case GET_PARKINGS_FAIL:
      return { loadingGetParkings: false, errorGetParkings: action.payload }
    case GET_PARKINGS_RESET:
      return {}
    default:
      return state
  }
}

export const addParkingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PARKING_REQUEST:
      return { loadingAddParking: true }
    case ADD_PARKING_SUCCESS:
      return { loadingAddParking: false, addParkingData: action.payload }
    case ADD_PARKING_FAIL:
      return { loadingAddParking: false, errorAddParking: action.payload }
    case ADD_PARKING_RESET:
      return {}
    default:
      return state
  }
}

export const getParkingByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PARKING_BY_ID_REQUEST:
      return { loadingGetParkingById: true }
    case GET_PARKING_BY_ID_SUCCESS:
      return {
        loadingGetParkingById: false,
        getParkingByIdData: action.payload,
      }
    case GET_PARKING_BY_ID_FAIL:
      return {
        loadingGetParkingById: false,
        errorGetParkingById: action.payload,
      }
    case GET_PARKING_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const updateParkingReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PARKING_REQUEST:
      return { loadingUpdateParking: true }
    case UPDATE_PARKING_SUCCESS:
      return {
        loadingUpdateParking: false,
        updateParkingData: action.payload,
      }
    case UPDATE_PARKING_FAIL:
      return {
        loadingUpdateParking: false,
        errorUpdateParking: action.payload,
      }
    case UPDATE_PARKING_RESET:
      return {}
    default:
      return state
  }
}
