import {
  DELETE_CHECKINS_FAIL,
  DELETE_CHECKINS_REQUEST,
  DELETE_CHECKINS_RESET,
  DELETE_CHECKINS_SUCCESS,
  DOWNLOAD_FAIL,
  DOWNLOAD_REQUEST,
  DOWNLOAD_RESET,
  DOWNLOAD_SUCCESS,
  FOC_FAIL,
  FOC_REQUEST,
  FOC_RESET,
  FOC_SUCCESS,
  GET_CHECKINS_FAIL,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_RESET,
  GET_CHECKINS_SUCCESS,
  MANUAL_CHECKOUT_FAIL,
  MANUAL_CHECKOUT_REQUEST,
  MANUAL_CHECKOUT_RESET,
  MANUAL_CHECKOUT_SUCCESS,
} from '../constants/checkinConstants'

export const getCheckinsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHECKINS_REQUEST:
      return { loadingGetCheckins: true }
    case GET_CHECKINS_SUCCESS:
      return { loadingGetCheckins: false, getCheckinsData: action.payload }
    case GET_CHECKINS_FAIL:
      return { loadingGetCheckins: false, errorGetCheckins: action.payload }
    case GET_CHECKINS_RESET:
      return {}
    default:
      return state
  }
}

export const manualCheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_CHECKOUT_REQUEST:
      return { loadingManualCheckout: true }
    case MANUAL_CHECKOUT_SUCCESS:
      return {
        loadingManualCheckout: false,
        manualCheckoutData: action.payload,
      }
    case MANUAL_CHECKOUT_FAIL:
      return {
        loadingManualCheckout: false,
        errorManualCheckout: action.payload,
      }
    case MANUAL_CHECKOUT_RESET:
      return {}
    default:
      return state
  }
}

export const focReducer = (state = {}, action) => {
  switch (action.type) {
    case FOC_REQUEST:
      return { loadingFoc: true }
    case FOC_SUCCESS:
      return {
        loadingFoc: false,
        focData: action.payload,
      }
    case FOC_FAIL:
      return {
        loadingFoc: false,
        errorFoc: action.payload,
      }
    case FOC_RESET:
      return {}
    default:
      return state
  }
}

export const downloadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUEST:
      return { loadingDownload: true }
    case DOWNLOAD_SUCCESS:
      return {
        loadingDownload: false,
        downloadData: action.payload,
      }
    case DOWNLOAD_FAIL:
      return {
        loadingDownload: false,
        errorDownload: action.payload,
      }
    case DOWNLOAD_RESET:
      return {}
    default:
      return state
  }
}

export const deleteCheckinsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHECKINS_REQUEST:
      return { loadingDeleteCheckins: true }
    case DELETE_CHECKINS_SUCCESS:
      return {
        loadingDeleteCheckins: false,
        deleteCheckinsData: action.payload,
      }
    case DELETE_CHECKINS_FAIL:
      return {
        loadingDeleteCheckins: false,
        errorDeleteCheckins: action.payload,
      }
    case DELETE_CHECKINS_RESET:
      return {}
    default:
      return state
  }
}
