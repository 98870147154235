import {
  ADD_RATE_FAIL,
  ADD_RATE_REQUEST,
  ADD_RATE_RESET,
  ADD_RATE_SUCCESS,
  GET_RATES_FAIL,
  GET_RATES_REQUEST,
  GET_RATES_RESET,
  GET_RATES_SUCCESS,
  GET_RATE_BY_ID_FAIL,
  GET_RATE_BY_ID_REQUEST,
  GET_RATE_BY_ID_RESET,
  GET_RATE_BY_ID_SUCCESS,
  UPDATE_RATE_FAIL,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_RESET,
  UPDATE_RATE_SUCCESS,
} from '../constants/rateConstants'

export const getRatesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RATES_REQUEST:
      return { loadingGetRates: true }
    case GET_RATES_SUCCESS:
      return { loadingGetRates: false, getRatesData: action.payload }
    case GET_RATES_FAIL:
      return { loadingGetRates: false, errorGetRates: action.payload }
    case GET_RATES_RESET:
      return {}
    default:
      return state
  }
}

export const getRateByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RATE_BY_ID_REQUEST:
      return { loadingGetRateById: true }
    case GET_RATE_BY_ID_SUCCESS:
      return { loadingGetRateById: false, getRateByIdData: action.payload }
    case GET_RATE_BY_ID_FAIL:
      return { loadingGetRateById: false, errorGetRateById: action.payload }
    case GET_RATE_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const addRateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_RATE_REQUEST:
      return { loadingAddRate: true }
    case ADD_RATE_SUCCESS:
      return { loadingAddRate: false, addRateData: action.payload }
    case ADD_RATE_FAIL:
      return { loadingAddRate: false, errorAddRate: action.payload }
    case ADD_RATE_RESET:
      return {}
    default:
      return state
  }
}

export const updateRateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RATE_REQUEST:
      return { loadingUpdateRate: true }
    case UPDATE_RATE_SUCCESS:
      return {
        loadingUpdateRate: false,
        updateRateData: action.payload,
      }
    case UPDATE_RATE_FAIL:
      return {
        loadingUpdateRate: false,
        errorUpdateRate: action.payload,
      }
    case UPDATE_RATE_RESET:
      return {}
    default:
      return state
  }
}
