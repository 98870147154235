import {
  GET_PASSES_FAIL,
  GET_PASSES_REQUEST,
  GET_PASSES_RESET,
  GET_PASSES_SUCCESS,
} from '../constants/passConstants'

export const getPassesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PASSES_REQUEST:
      return { loadingGetPasses: true }
    case GET_PASSES_SUCCESS:
      return { loadingGetPasses: false, getPassesData: action.payload }
    case GET_PASSES_FAIL:
      return { loadingGetPasses: false, errorGetPasses: action.payload }
    case GET_PASSES_RESET:
      return {}
    default:
      return state
  }
}
